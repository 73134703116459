import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Tag from "./Tag";

function InputTags({ onUpdate, existingTags, label, description }) {
  const [text, setText] = useState("");

  const [tags, setTags] = useState();

  const handleDelete = (target) => {
    setTags(tags.filter((tag, index) => tag.id !== target.id));
  };

  const handleAddition = (tag) => {
    setTags([...existingTags, tag]);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const value = e.target.value.replace(/\r?\n|\r/g, "");
      if (value.length > 0) {
        const tag = { id: uuidv4(), text: value };
        handleAddition(tag);
        setText("");
      }
    }
  };

  useEffect(() => {
    onUpdate(tags);
  }, [tags]);

  return (
    <div className="flex flex-col gap-y-2">
      <div className="text-xs flex flex-col">
        <label className="font-bold uppercase text-xs">{label}</label>
        <span>{description}</span>
      </div>

      <input
        className="border border-gray/20 p-2 rounded w-full outline-none"
        onKeyDown={(e) => handleKeyPress(e)}
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      {/* tags section */}
      <div className="mt-3 flex gap-x-2 ">
        {existingTags &&
          existingTags.map((tag, index) => {
            return (
              <Tag
                name={tag.text}
                key={index}
                onDelete={() => handleDelete(tag)}
              />
            );
          })}
      </div>
    </div>
  );
}

export default InputTags;
