import React, { useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import { useLocation } from "react-router";
import Navbar from "../components/Navbar/Navbar";
import { twMerge } from "tailwind-merge";

function PageLayout({ children }) {
  const location = useLocation();
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <div className={`grid grid-cols-12`}>
        {!location.pathname.includes("superadmin") ? (
          <>
            <div
              className={`bg-base hidden md:inline ${
                isOpenSidebar ? "col-span-2" : "col-span-1"
              } h-full`}
            >
              <div className="sticky top-0 z-40 hidden md:inline">
                <Sidebar
                  isOpen={isOpenSidebar}
                  setOpenClose={setIsOpenSidebar}
                />
              </div>
            </div>
            <div
              className={twMerge(
                "col-span-12 px-4 md:pl-10 bg-base",
                isOpenSidebar ? "md:col-span-10" : "md:col-span-11"
              )}
            >
              {children}
            </div>
          </>
        ) : (
          <div className={`col-span-12 px-10 bg-base`}>{children}</div>
        )}
      </div>
    </div>
  );
}

export default PageLayout;
