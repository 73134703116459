import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaTrash, FaUsers } from "react-icons/fa";
import { deleteChatbot } from "../../services/api/ManagerService";
import { useChatbotCountState } from "../../states/useStates";
import { ClipLoader } from "react-spinners";
import ModalDeleteChatbot from "../Modal/ModalDeleteChatbot";
import { TableCell, TableRow } from "@/components/ui/table";

function KnowledgeBaseSingleRow({ item, onDelete, onUserView, isSelected }) {
  const navigate = useNavigate();
  const [executeDeleteSpinner, setExecuteDeleteSpinner] = useState(false);
  const [chatbotCount, setChatbotCount] = useChatbotCountState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const handleView = (datastore, config_id) => {
    navigate(`/knowledgebase/${datastore}`, {
      state: {
        config: item,
      },
    });
  };

  const handleDelete = async (target) => {
    console.log("si");
    setIsDeleteModalOpen(false);
    setExecuteDeleteSpinner(true);
    await deleteChatbot(target.chatbot_name);
    setChatbotCount(chatbotCount - 1);
    onDelete(target);
    setExecuteDeleteSpinner(false);
  };

  return (
    <TableRow className="bg-white rounded">
      <TableCell className="font-medium">{item.chatbot_name}</TableCell>
      <TableCell>
        {item.status === "in progress" ? (
          <span>IN PROGRESS</span>
        ) : (
          <span>ACTIVE</span>
        )}
      </TableCell>
      <TableCell>
        <div className="rounded-full p-2 bg-primary w-8 h-8 text-white flex items-center justify-center">
          {item.user_fullname.replace(/[^A-Z]/g, "")}
        </div>
      </TableCell>
      <TableCell>{item.documents_count}</TableCell>
      <TableCell>{item.time_created}</TableCell>
      <TableCell className="text-right">
        {item.status !== "in progress" && (
          <div className="flex gap-5 justify-end">
            <button
              className="border border-primary p-3 rounded text-primary hover:bg-primary hover:text-white text-md"
              onClick={() => handleView(item.chatbot_name, item["_id"])}
            >
              <FaEye />
            </button>
            <button
              className="border border-primary p-3 rounded text-primary hover:bg-primary hover:text-white text-md"
              onClick={() => onUserView(item)}
            >
              <FaUsers />
            </button>
            {!executeDeleteSpinner ? (
              <>
                <button
                  className="border border-red-500 p-3 rounded text-red-500 hover:bg-red-500 hover:text-white text-md"
                  onClick={() => setIsDeleteModalOpen((prev) => !prev)}
                >
                  <FaTrash />
                </button>
                <ModalDeleteChatbot
                  isOpen={isDeleteModalOpen}
                  setIsOpen={setIsDeleteModalOpen}
                  onSubmit={handleDelete}
                  chatbot={item}
                />
              </>
            ) : (
              <button className="border border-red-500 p-3 rounded text-red-500">
                <ClipLoader color="red" size={8} />
              </button>
            )}
          </div>
        )}
      </TableCell>
    </TableRow>
  );
}

export default KnowledgeBaseSingleRow;
