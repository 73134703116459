import React from "react";
import Input from "../../components/Forms/Input";
import { AiOutlineSearch } from "react-icons/ai";

function Omnisearch({ onSearch }) {
  return (
    <div className="flex items-center gap-x-3 relative">
      <Input
        placeholder={"Search..."}
        width={"w-full"}
        onChange={(e) => onSearch(e.target.value)}
      />
      <AiOutlineSearch className="text-2xl absolute right-5 bottom-2 opacity-50" />
    </div>
  );
}

export default Omnisearch;
