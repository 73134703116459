import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import FreeTextFilesList from "./FreeTextFilesList";
import StructuredTextFileList from "./StructuredTextFileList";
import { getValidFormats, extractFileExtensions } from "../../services/utils";
import { toast } from "react-hot-toast";

const Dropzone = ({ className, botInfo, files, setFiles }) => {
  const validFormats = getValidFormats(botInfo.connector_type);

  let filelist;

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length) {
      setFiles((previousFiles) => [
        ...previousFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        ),
      ]);
    }
  }, []);

  // Metodo di validazione di react-dropzone
  const validateFileFormat = (file) => {
    const extension = extractFileExtensions(file?.name);
    if (validFormats.includes(extension)) {
      return validateFreeTextFileSize(file);
    } else {
      toast.error("File type not valid.");
      return {
        message: "File type not valid.",
      };
    }
  };

  const validateFreeTextFileSize = (file) => {
    if (file.type === "application/pdf" && file.size >= 100 * 1024 * 1024) {
      toast.error("The size limit for pdf is 100mb.");
      return {
        message: "The file is too large.",
      };
    } else {
      return null;
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    validator: validateFileFormat,
  });

  if (botInfo.connector_type === "free-text") {
    filelist = <FreeTextFilesList files={files} setFiles={setFiles} />;
  } else if (botInfo.connector_type === "structured-text") {
    filelist = <StructuredTextFileList />;
  }

  return (
    <div>
      <div
        {...getRootProps({
          className: className,
        })}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="text-center">Drop the files here ...</p>
        ) : (
          <div className="text-center">
            <p>Drag 'n' drop your files here, or click to select files</p>
            <p className="text-xs text-gray text-opacity-50">
              Accepted formats:{" "}
              {validFormats.map((format) => " " + format + ",")}
            </p>
          </div>
        )}
      </div>
      {filelist}
    </div>
  );
};

export default Dropzone;
