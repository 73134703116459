import React, { useState, useEffect } from "react";
import SelectWithIcons from "../Forms/SelectWithIcons";
import SelectBase from "../Forms/SelectBase";
import {
  fetchChatbots,
  fetchChatbotConfig,
} from "../../services/api/ManagerService";
import TextArea from "./TextArea";
import { useCurrentChatbotConfigState } from "../../states/useStates";

function PlaygroundTools({ onChange }) {
  const [chatbotList, setChatbotList] = useState([]);
  const [currentConfig, setCurrentConfig] = useCurrentChatbotConfigState();
  const [newConfig, setNewConfig] = useState();
  const chatbotModels = [
    {
      value: "gpt-3.5-turbo-16k",
      label: "gpt-3.5-turbo-16k",
      provider: "openai",
      available: true,
      icon: (
        <img
          src={process.env.PUBLIC_URL + "/images/technologies/openai.png"}
          width={20}
        />
      ),
    },
    {
      value: "gpt-4",
      label: "gpt-4",
      provider: "openai",
      available: true,
      icon: (
        <img
          src={process.env.PUBLIC_URL + "/images/technologies/openai.png"}
          width={20}
        />
      ),
    },
    {
      value: "PaLM2",
      label: "PaLM2",
      provider: "google",
      available: false,
      icon: (
        <img
          src={process.env.PUBLIC_URL + "/images/technologies/PaLM.png"}
          width={20}
        />
      ),
    },
  ];
  const [filteredChatbotModels, setFilteredChatbotModels] =
    useState(chatbotModels);
  const [selectedChatbot, setSelectedChatbot] = useState({});
  const [selectedModel, setSelectedModel] = useState();
  const [prompt, setPrompt] = useState("");

  const getModelSelectedModel = (target) => {
    const result = chatbotModels.filter((obj) => {
      return obj.value === target;
    })[0];
    return result;
  };

  // const getConfigID = () => {
  //   if (Object.keys(selectedChatbot).length > 0) {
  //     const result = chatbotList.filter((obj) => {
  //       return obj.chatbot_name === selectedChatbot.chatbot_name;
  //     })[0];
  //     setChatbotConfigID(result.id);
  //     return result.id;
  //   }
  // };

  const handleSelectModel = (e) => {
    if (e.provider !== "google") {
      setSelectedModel(e);
      setNewConfig({
        ...currentConfig,
        chatbot_model: e.value,
        chatbot_model_provider: e.provider,
      });
    }
  };

  const handlePromptChange = (p) => {
    setPrompt(p);
    setNewConfig({
      ...currentConfig,
      system_prompt: p,
    });
  };

  const getConfiguration = async () => {
    if (Object.keys(selectedChatbot).length > 0) {
      const res = await fetchChatbotConfig(selectedChatbot.chatbot_name).catch(
        (e) => console.log(e)
      );
      setCurrentConfig({ ...res, chatbot_name: selectedChatbot.value });
    }
  };

  useEffect(() => {
    const fn = async () => {
      const chatbots = await fetchChatbots().catch((e) => console.log(e));
      setChatbotList(chatbots);
    };
    fn();
  }, []);

  useEffect(() => {
    getConfiguration();
    if (selectedChatbot.connector_type === "structured-text") {
      // remove gpt-3.5-turbo-16k from filteredChatbotModels
      const updatedChatbotModels = filteredChatbotModels.filter((obj) => {
        return obj.value !== "gpt-3.5-turbo-16k";
      });

      setFilteredChatbotModels(updatedChatbotModels);
      setSelectedModel(updatedChatbotModels[0]);
    } else {
      setFilteredChatbotModels(chatbotModels);
    }
  }, [selectedChatbot]);

  useEffect(() => {
    if (Object.keys(currentConfig).length > 0) {
      setNewConfig(currentConfig);
      setPrompt(currentConfig.system_prompt);
      setSelectedModel(getModelSelectedModel(currentConfig.chatbot_model));
    } else {
      setPrompt("");
      setSelectedModel("");
    }
  }, [currentConfig]);

  useEffect(() => {
    if (Object.keys(currentConfig).length > 0) {
      onChange(newConfig);
    }
  }, [newConfig]);

  return (
    <div className="flex flex-col gap-y-5 h-full">
      <div className="w-full flex-col flex lg:flex-row gap-4 gap-x-3 mt-5">
        <SelectBase
          styleClass={"w-full"}
          label={"Chatbot"}
          options={chatbotList}
          field="chatbot_name"
          onChange={(e) => setSelectedChatbot(e)}
        />
        {/* <SelectWithIcons
          styleClass={"w-full"}
          label={"Model"}
          options={filteredChatbotModels}
          value={selectedModel}
          field="name"
          onChange={(e) => handleSelectModel(e)}
        /> */}
      </div>

      <TextArea
        placeholder="You are an helpful assistant. Your tasks are:"
        label={"PROMPTING INSTRUCTIONS"}
        text={prompt}
        styleClass={"h-screen py-2"}
        onUpdate={(p) => handlePromptChange(p)}
      />
    </div>
  );
}

export default PlaygroundTools;
