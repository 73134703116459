import React, { useEffect, useState } from "react";
import { promptScoreState } from "../../states/atoms";
import { useRecoilValue } from "recoil";
import { Tooltip as ReactTooltip } from "react-tooltip";
import MultlineText from "../MultilineText/MultilineText";

function PromptQuality({ status }) {
  const [hoverActive, setHoverActive] = useState(true);
  const score = useRecoilValue(promptScoreState);
  const [feedback, setFeedback] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [promptColor, setPromptColor] = useState("bg-grayCustom/30");

  const extractScoreValue = () => {
    const scoreRegex = /\b(\d+(\.\d+)?%)/g;
    const match = score.match(scoreRegex);

    if (match) {
      const score = match[0].replace("%", "");
      // Extract the score value
      if (score > 50 && score < 90) {
        setPromptColor("bg-yellow-800");
      } else if (score >= 90) {
        setPromptColor("bg-green-800");
      } else {
        setPromptColor("bg-red-800");
      }
    } else {
      console.log("SCORE not found in the input string.");
    }
  };

  const extractFeeback = () => {
    const feedbackRegex = /FEEDBACK:(.*?)$/s; // Use the 's' flag to match across multiple lines
    const match = score.match(feedbackRegex);

    if (match) {
      const feedbackExtracted = match[1].trim(); // Extract and trim the feedback section
      setFeedback(feedbackExtracted.replace(/<[^>]*>/g, ""));
    } else {
      console.log("FEEDBACK section not found in the input string.");
    }
  };

  useEffect(() => {
    extractScoreValue();
    extractFeeback();
  }, [score]);

  return (
    <>
      <div
        className="text-xs bg-primary/80 border border-primary/50 p-2 text-white rounded flex gap-x-3 items-center cursor-help w-fit relative"
        data-tooltip-id="my-tooltip-1"
        onClick={() => setShowFeedback(!showFeedback)}
      >
        <span className="text-white">Prompt Quality</span>
        <div className={`h-4 w-4 ${promptColor} rounded-full`}></div>
      </div>
      {score && showFeedback ? (
        // <ReactTooltip
        //   id="my-tooltip-1"
        //   place="bottom"
        //   content={<MultlineText text={feedback} />}
        //   multiline={true}
        //   className="custom-react-tooltip"
        // />
        <div className="bg-primary text-white absolute top-40 p-5 w-1/3 right-10 rounded-xl">
          <MultlineText text={feedback} />
        </div>
      ) : (
        <ReactTooltip
          id="my-tooltip-1"
          place="bottom"
          content={
            <MultlineText
              text={
                "For each answer instruction we will generate a feedback\non your prompt to help you to improve it\nIf the dot is colored, click on to see the feedback"
              }
            />
          }
          className="custom-react-tooltip"
        />
      )}
    </>
  );
}

export default PromptQuality;
