import { FaDatabase, FaRobot } from "react-icons/fa6";
import { FaTools } from "react-icons/fa";
import { BsFillChatFill } from "react-icons/bs";
import { MdAdminPanelSettings } from "react-icons/md";
import { IoChatbubbles } from "react-icons/io5";

let sidebarMenu = [
  {
    title: "Chatbots",
    icon: <FaRobot className="text-2xl" />,
    to: "/",
  },
  // {'title': 'Chatbots', 'icon': <FaRobot className='text-2xl'/>, 'to': '/builder/chatbots'},
  {
    title: "Playground",
    icon: <FaTools className="text-2xl" />,
    to: "/playground",
  },
  { title: "Chat", icon: <IoChatbubbles className="text-2xl" />, to: "/chat" },
  {
    title: "Admin Area",
    icon: <MdAdminPanelSettings className="text-3xl" />,
    to: "/admin",
  },
];

export { sidebarMenu };
