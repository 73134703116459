import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { MdLogout, MdEmail } from "react-icons/md";
import { useRecoilValue } from "recoil";

function User({ fullname, company, minimize }) {
  const navigate = useNavigate();
  const [showBox, setShowBox] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("localAuthorization");
    localStorage.removeItem("fullname");
    navigate("/");
    window.location.reload(true);
  };

  return (
    <div className="relative z-50">
      <div
        className="flex gap-x-3 items-center cursor-pointer"
        onClick={() => setShowBox(!showBox)}
      >
        <div className="rounded-full bg-primary p-2">
          <span className="font-bold text-white">
            {fullname.replace(/[^A-Z]/g, "")}
          </span>
          <span></span>
        </div>
        {minimize ? (
          ""
        ) : (
          <div className="flex flex-col">
            <div className="text-xs">{fullname}</div>
            <div className="text-xs font-bold">{company}</div>
          </div>
        )}
      </div>

      {/* options on click */}
      {showBox ? (
        <div className="bg-white px-3 py-3 shadow absolute -top-20 -right- text-primary">
          <ul className="text-xs flex flex-col gap-y-2">
            <li className="flex gap-x-2 items-center cursor-pointer hover:underline">
              <MdEmail className="" />
              Contact Us
            </li>
            <li
              className="flex gap-x-2 items-center cursor-pointer hover:underline"
              onClick={handleLogout}
            >
              <MdLogout className="" />
              Logout
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default User;
