import React from "react";
import { twMerge } from "tailwind-merge";

function Input({
  className,
  label,
  type,
  placeholder,
  value,
  width,
  onChange,
  isDisabled,
}) {
  return (
    <div className={`flex flex-col ${width}`}>
      <label className="text-gray text-xs font-bold mb-2 uppercase">
        {label}
      </label>
      <input
        onChange={onChange}
        className={twMerge(`h-fit p-2 outline-0 bg-white border border-gray/20 rounded`, className)}
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={isDisabled}
        autoComplete="off"
      />
    </div>
  );
}

Input.defaultProps = {
  placeholder: "",
  width: "w-full",
};

export default Input;
