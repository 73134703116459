import React, { useState, useRef } from "react";
import { extractFileExtensions } from "../../services/utils";
import { useNotValidFilesState } from "../../states/useStates";
import { fileValidation } from "../../services/utils";
import { cn } from "@/lib/utils";

function UploadZone({
  handleFileChange,
  formatExplanation,
  validFormat,
  connectorType,
}) {
  const [dragActive, setDragActive] = useState(false);
  const [notValidFiles, setNotValidFiles] = useNotValidFilesState();
  // const [validFiles, setValidFiles] = useState();
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      const validFiles = fileValidation(validFormat, e.dataTransfer.files);
      // console.log("dropped files: ", validFiles);
      handleFileChange(validFiles);
    }
  };

  const handleBrowseFile = (e) => {
    const validFiles = fileValidation(
      validFormat,
      e.target.files,
      connectorType
    );
    handleFileChange(validFiles);
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <div className="p-5 bg-white rounded shadow h-60 w-full lg:h-96">
      <div
        className={cn(
          "flex items-center justify-center w-full p-5 border border-dotted border-gray-600/90 rounded h-full ",
          dragActive && "bg-primary/30"
        )}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            aria-hidden="true"
            className="w-10 h-10 mb-3 text-gray-800 text-opacity-50"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            ></path>
          </svg>
          <p className="text-sm text-gray-800 text-opacity-50">
            <span className="font-semibold">Drag & Drop</span>
          </p>
          <p className="text-sm text-gray-800 text-opacity-50 mb-5">
            Your files, or
            <span
              className="underline cursor-pointer ml-1"
              onClick={onButtonClick}
            >
              browse
            </span>
          </p>
          <p className="text-xs text-gray-800 text-opacity-50">Accepted Format:</p>
          <ul className="flex items-center gap-1 text-xs text-gray-800 text-opacity-50">
            {validFormat.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        </div>
        <input
          ref={inputRef}
          id="dropzone-file"
          type="file"
          className="hidden"
          onChange={(e) => handleBrowseFile(e)}
          multiple
        />
      </div>
    </div>
  );
}

export default UploadZone;
