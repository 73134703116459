import React, { useState, useEffect } from "react";
import Input from "../../components/Forms/Input";
import {
  associateConnectorToCompany,
  fetchAvailableConnectors,
  createCompanyConstraint,
  createCompany,
} from "../../services/api/SuperadminService";
import Checkbox from "../../components/Forms/Checkbox";

function NewCompanyForm() {
  const [availableConnectors, setAvailableConnectors] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [s3bucket, setS3bucket] = useState("");
  const [s3bucketKey, setS3bucketKey] = useState("");
  const [awsRegion, setAwsRegion] = useState("eu-west-1");
  const [vectorestoreProvider, setVectorestoreProvider] = useState("elastic"); // ["elastic", "aws"
  const [vectorestoreHost, setVectorestoreHost] = useState("");
  const [vectorestorePort, setVectorestorePort] = useState("");
  const [vectorestoreUser, setVectorestoreUser] = useState("");
  const [vectorestorePassword, setVectorestorePassword] = useState("");
  const [elasticCloudId, setElasticCloudId] = useState("");
  const [openaiApiKey, setOpenaiApiKey] = useState("");
  const [maxChatbots, setMaxChatbots] = useState("");
  const [maxDailyQueries, setMaxDailyQueries] = useState("");
  const [maxDatasourcesSizeMb, setMaxDatasourcesSizeMb] = useState("");
  const [connectors, setConnectors] = useState([]);

  const handleAddedConnector = (id) => {
    if (connectors.includes(id)) {
      const new_connectors = connectors.filter((connector) => connector !== id);
      setConnectors(new_connectors);
    } else {
      setConnectors([...connectors, id]);
    }
  };

  useEffect(() => {
    fetchAvailableConnectors().then((res) => {
      setAvailableConnectors(res.data);
    });
  }, []);

  const submitForm = async () => {
    const companyPayload = {
      company_name: companyName,
      s3_bucket: s3bucket,
      s3_bucket_key: s3bucketKey,
      vectorestore_host: vectorestoreHost,
      vectorestore_user: vectorestoreUser,
      vectorestore_password: vectorestorePassword,
      vectorestore_port: vectorestorePort,
      vectorestore_provider: vectorestoreProvider,
      openai_api_key: openaiApiKey,
      elasticsearch_cloud_id: elasticCloudId,
    };

    const resCompany = await createCompany(companyPayload);
    const company_id = resCompany.data.id;

    if (resCompany.status === 200) {
      const companyConstraintsPayload = {
        max_daily_query: maxDailyQueries,
        max_chatbots: maxChatbots,
        max_datasource_size_mb: maxDatasourcesSizeMb,
      };

      const resCompanyConstraints = await createCompanyConstraint(
        company_id,
        companyConstraintsPayload
      );

      if (resCompanyConstraints.status === 200) {
        for (let i = 0; i < connectors.length; i++) {
          const resConnector = await associateConnectorToCompany(company_id, {
            connector_id: connectors[i],
          });
        }
      }
    }

    if (resCompany.status === 409) {
      alert("Company already exists or S3 bucket key is already in use.");
    }

    if (resCompany.status === 200) {
      window.location.reload();
    }
  };

  return (
    <div>
      {/* new company form */}
      <div className="flex justify-center items-center z-50 overflow-x-hidden right-5">
        <div className="bg-white w-4/5 rounded shadow">
          <div className="p-10">
            <h1 className="text-xl">Create New Company</h1>

            <div className="mt-5">Company Info</div>
            <div className="flex items-center gap-5 mt-5">
              <Input
                label={"Company Name*"}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <Input
                label={"S3 Bucket*"}
                value={s3bucket}
                onChange={(e) => setS3bucket(e.target.value)}
              />
              <Input
                label={"S3 Bucket Key*"}
                value={s3bucketKey}
                onChange={(e) => setS3bucketKey(e.target.value)}
              />
              <Input
                label={"AWS Region*"}
                value={awsRegion}
                onChange={(e) => setAwsRegion(e.target.value)}
              />
            </div>

            <hr className="mt-5 mb-5" />
            <div className="mt-5">Vectorestore Configuration</div>

            <div className="flex items-center gap-5 mt-5">
              <Input
                label={"Vectorestore provider*"}
                value={vectorestoreProvider}
                isDisabled={true}
              />
              <Input
                label={"Vectorestore Host*"}
                value={vectorestoreHost}
                onChange={(e) => setVectorestoreHost(e.target.value)}
              />
              <Input
                label={"Vectorestore port*"}
                value={vectorestorePort}
                onChange={(e) => setVectorestorePort(e.target.value)}
              />
            </div>
            <div className="flex items-center gap-5 mt-5">
              <Input
                label={"Vectorestore User*"}
                value={vectorestoreUser}
                onChange={(e) => setVectorestoreUser(e.target.value)}
              />
              <Input
                label={"Vectorestore password*"}
                value={vectorestorePassword}
                onChange={(e) => setVectorestorePassword(e.target.value)}
              />
              <Input
                label={"Elasticsearch Cloud ID*"}
                value={elasticCloudId}
                onChange={(e) => setElasticCloudId(e.target.value)}
              />
            </div>
            <hr className="mt-5 mb-5" />
            <div className="mt-5">OpenAI</div>
            <div className="flex items-center gap-5 mt-5">
              <Input
                label={"OpenAI API KEY*"}
                width={"w-1/3"}
                value={openaiApiKey}
                onChange={(e) => setOpenaiApiKey(e.target.value)}
              />
            </div>
            <hr className="mt-5 mb-5" />
            <div className="mt-5">Constraints</div>
            <div className="flex items-center gap-5 mt-5">
              <Input
                label={"Max Chatbots*"}
                value={maxChatbots}
                onChange={(e) => setMaxChatbots(e.target.value)}
              />
              <Input
                label={"Max Daily Queries*"}
                value={maxDailyQueries}
                onChange={(e) => setMaxDailyQueries(e.target.value)}
              />
              <Input
                label={"Max Datasources Size MB*"}
                value={maxDatasourcesSizeMb}
                onChange={(e) => setMaxDatasourcesSizeMb(e.target.value)}
              />
            </div>

            <hr className="mt-5 mb-5" />
            <div className="mt-5">Connectors</div>
            <div className="flex items-center gap-5 mt-5">
              {availableConnectors.map((connector) => (
                <div className="flex items-center gap-3">
                  <Checkbox
                    onClick={() => handleAddedConnector(connector.id)}
                  />
                  <label>{connector.connector_name}</label>
                </div>
              ))}
            </div>
            <div className="mt-5 w-full">
              <button
                className="bg-primary text-white w-full p-3 rounded"
                onClick={submitForm}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* new company form - end */}
    </div>
  );
}

export default NewCompanyForm;
