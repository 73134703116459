import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  deleteChatbot,
  fetchDatasources,
  uploadDatasources,
} from "../../services/api/ManagerService";
import { executePipeline } from "../../services/api/PipelineService";
import CardBase from "../../components/Card/CardBase";
import BaseButton from "../../components/Buttons/BaseButton";
import { extractFileExtensions } from "../../services/utils";
import Omnisearch from "../../components/Omnisearch/Omnisearch";
import { FaArrowLeft } from "react-icons/fa6";
import {ClipLoader} from "react-spinners";
import {
  useChatbotCountState,
  useModalUploadFileState,
} from "../../states/useStates";
import PageLayout from "../PageLayout";
import { toast } from "react-hot-toast";
import Dropzone from "../../components/Dropzone/Dropzone";
import ModalFileUpload from "../../components/Modal/ModalFileUpload";
import { addTagsToDatasource } from "../../services/api/ManagerService";

function ViewSingleKnowledgeBase() {
  const location = useLocation();
  const navigate = useNavigate();
  const [stateLocal] = useState(location.state);
  const [datasources, setDatasources] = useState([]);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState({});
  const [filteredDatasource, setFilteredDatasource] = useState(datasources);
  const [files, setFiles] = useState([]);
  const [executeDeleteSpinner, setExecuteDeleteSpinner] = useState(false);
  const [executePipelineSpinner, setExecutePipelineSpinner] = useState(false);
  const [chatbotCount, setChatbotCount] = useChatbotCountState();
  const [showModal, setShowModal] = useModalUploadFileState(false);

  const configureFileTags = (connector) => {
    let files_tags = [];

    // if (connector === "free-text") {
    //   files.map((file) => {
    //     let tags = {};
    //     const description = file.description;

    //     file.tags.forEach((tag) => {
    //       const [key, value] = tag.text.split("=");
    //       if (key && value) {
    //         tags[key] = value;
    //       }
    //     });
    //     tags["description"] = description;
    //     files_tags.push(tags);
    //   });
    // } else
    if (connector === "structured-text") {
      files.map((file) => {
        let tags = {};
        const description = file.table_description;
        tags["description"] = description;
        tags["column_description"] = file.tags;
        files_tags.push(tags);
      });
    }

    return files_tags;
  };

  const handleFileUpload = async () => {
    const chatbotName = stateLocal.config.chatbot_name;
    // console.log(chatbotName);
    // console.log(files);

    // Tutto da testare, non l'ho ancora provato, manca la parte di pipline
    const res_uploadDatasource = await uploadDatasources(
      chatbotName,
      files
    ).catch((err) => {
      toast.error("Failed during file upload: " + err.response.data.detail);
    });

    let fileTags = [];
    if (stateLocal.config.connector_type === "free-text") {
      fileTags = configureFileTags("free-text");
    } else if (stateLocal.config.connector_type === "structured-text") {
      fileTags = configureFileTags("structured-text");
    } else {
      fileTags = [];
    }

    if (fileTags.length > 0) {
      console.log("Add tags");
      res_uploadDatasource.map((item, index) => {
        addTagsToDatasource(chatbotName, item.id, {
          tags: fileTags[index],
        });
      });
    }

    const pipelinePayload = {
      chatbot_name: chatbotName,
      company_id: localStorage.getItem("company_id"),
    };
    await executePipeline(
      stateLocal.config.connector_type,
      pipelinePayload
    ).catch((err) => {
      console.log(err);
      toast.error("Failed during embeddings: " + err);
    });

    toast.success(
      "Files uploaded successfully. You will receive a notification when the pipeline is completed."
    );
    setFiles([]);
  };

  const handleExecutePipeline = () => {
    setExecutePipelineSpinner(true);
    executePipeline(stateLocal.config, files, () => {
      setExecutePipelineSpinner(false);
      setFiles([]);
      fetchData();
    });
    // window.location.reload();
  };

  const fetchData = async () => {
    fetchDatasources(stateLocal.config.chatbot_name)
      .then((res) => {
        // console.log(res);
        setDatasources(res);
        setFilteredDatasource(res);
      })
      .catch((err) => console.log(err));
  };

  const handleDetailsView = (item) => {
    setSelectedSource(item);
    setDetailsOpen(true);
  };

  const handleDelete = async (target) => {
    if (window.confirm("Are you sure you want to delete the knowledge base?")) {
      setExecuteDeleteSpinner(true);
      await deleteChatbot(target);
      setChatbotCount(chatbotCount - 1);
      navigate("/");
    }
  };

  const handleSearch = (target) => {
    if (target !== "") {
      setDetailsOpen(false);
      const filtered = datasources.datasource.filter((item) =>
        item.filename.toLowerCase().includes(target)
      );
      setFilteredDatasource(filtered);
    } else {
      setFilteredDatasource(datasources);
    }
  };

  useEffect(() => {
    fetchData().catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (!showModal) {
      setFiles([]);
    }
  }, [showModal]);

  // useEffect(() => {
  //   setExecutePipelineSpinner(false);
  // }, [datasources]);

  return (
    <PageLayout>
      <div className="pl-2 pr-4 lg:pl-0 pb-2 ">
        <div className="flex gap-x-10 md:mt-10 items-center justify-between h-full">
          <div className="flex gap-x-5 items-center">
            <Link to="/">
              <FaArrowLeft className="opacity-50 hover:opacity-100 cursor-pointer" />
            </Link>
            <h1 className="text-3xl font-bold">
              {stateLocal.config.chatbot_name}
            </h1>
          </div>
        </div>
        <div className="w-full mt-2">
          <p>{stateLocal.config.chatbot_description}</p>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-y-4 ">
          <div className="lg:w-1/2 lg:mt-4">
            <Omnisearch onSearch={handleSearch} />
          </div>
          <div className="flex items-end gap-5">
            <ModalFileUpload
              title="Upload new files"
              openActionLabel="Add new files"
              submitActionLabel="Upload"
              botConfig={stateLocal.config}
              onSubmit={handleFileUpload}
              bodyContent={
                <Dropzone
                  files={files}
                  setFiles={setFiles}
                  botInfo={stateLocal.config}
                  className="p-16 mt-4 rounded outline-dashed outline-[#cbd5e1]"
                />
              }
            />

            {executeDeleteSpinner ? (
              <BaseButton
                onClick={() => handleDelete(stateLocal.config.chatbot_name)}
                value={<ClipLoader size={20} color="red" />}
                styleClass={"border border-red-500 text-red-500"}
              />
            ) : (
              <BaseButton
                onClick={() => handleDelete(stateLocal.config.chatbot_name)}
                value={"Delete Datastore"}
                styleClass={
                  "border border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                }
              />
            )}
          </div>
        </div>

        <div
          className={`lg:mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5`}
        >
          {datasources &&
            datasources.map((d, index) => {
              return (
                <CardBase
                  onClick={() => handleDetailsView(d)}
                  key={index}
                  styleClass={`p-2 h-full w-full flex justify-start items-center gap-2 cursor-pointer hover:border hover:border-primary bg-white col-span-1 ${
                    selectedSource.filename === d.filename
                      ? "border border-primary"
                      : ""
                  }`}
                >
                  <img
                    src={`${
                      process.env.PUBLIC_URL
                    }/images/file_type/${extractFileExtensions(
                      d.filename,
                      true
                    )}.png`}
                    className="w-8 lg:w-12"
                    alt=""
                  />
                  <span className="text-xs truncate">{d.filename}</span>
                </CardBase>
              );
            })}
        </div>
      </div>
    </PageLayout>
  );
}

export default ViewSingleKnowledgeBase;
