import React, { useEffect, useState, useCallback } from "react";
import { fileValidation, getSizeDimension } from "../../../services/utils";
import { extractFileExtensions } from "../../../services/utils";
import { useNotValidFilesState } from "../../../states/useStates";
import { MdDeleteOutline } from "react-icons/md";
import { useRecoilState } from "recoil";
import {
  connectorErrorsState,
  isConnectorValidState,
} from "../../../states/atoms";
import Input from "../../Forms/Input";
import InputTags from "../../InputTags/InputTags";
import toast from "react-hot-toast";

function ListUploadedFile({
  handleFileRemove,
  handleFileChange,
  setFiles,
  files,
  validFormat,
}) {
  const [notValidFiles, setNotValidFiles] = useNotValidFilesState();
  const [total, setTotal] = useState(null);
  const hiddenFileInput = React.useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  const [description, setDescription] = useState("");

  const [isConnectorValid, setIsConnectorValid] = useRecoilState(
    isConnectorValidState
  );

  const [connectorErrors, setConnectorErrors] =
    useRecoilState(connectorErrorsState);



  const addMetadata = (target, metadata) => {
    const updatedFiles = files.map((item) => {
      if (item.name === target) {
        item.tags = metadata;
      }
      return item;
    });

    setFiles(updatedFiles);
  };

  const handleTags = (tags) => {
    if (selectedFile) {
      addMetadata(selectedFile.name, tags);
    }
  };

  const handleAddDescription = (target, description) => {
    setDescription(description);
    const updatedFiles = files.map((item) => {
      if (item.name === target) {
        item.description = description;
      }
      return item;
    });
    setFiles(updatedFiles);
  };

  const handleUploadClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileUpload = (e) => {
    const arr = Array.from(e.target.files);
    const validFiles = fileValidation(validFormat, arr, "free-text");

    handleFileChange(validFiles);
  };

  const notifyInvalidFiles = (files) => {
    toast(
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <div className="text-lg">⚠️</div>
          <h2>The following files are not valid for the selected connector:</h2>
        </div>
        <div className="flex flex-col gap-2">
          {files.map((file) => (
            <p>📄 {file.name}</p>
          ))}
        </div>
      </div>
    );
  };

  // turn off connector-specific validation
  useEffect(() => {
    setIsConnectorValid(true)
  }, );

  useEffect(() => {
    let totalSize = 0;
    files.map((item) => {
      totalSize += item.size;
    });

    setTotal(getSizeDimension(totalSize));
    if (selectedFile === undefined) {
      setSelectedFile(files[0]);
    } else {
      setSelectedFile(selectedFile);
    }
  }, [files, selectedFile]);

  useEffect(() => {
    setDescription(selectedFile?.description || "");
  }, [selectedFile]);

  return (
    <div className="relative overflow-x-auto p-5 bg-white rounded flex  gap-5 overflow-scroll shadow">
      <div className=" flex flex-col lg:w-1/3 gap-2">
        {files &&
          files.map((item, index) => (
            <div
              key={index}
              className={`${
                selectedFile && selectedFile.name === item.name
                  ? "bg-primary text-white"
                  : ""
              } p-2 border border-gray/20 rounded flex items-center justify-between cursor-pointer hover:bg-primary hover:text-white`}
              onClick={() => setSelectedFile(item)}
            >
              <div className="flex items-center gap-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/file_type/" +
                    extractFileExtensions(item.name, true) +
                    ".png"
                  }
                  alt="file extension icon"
                  className="h-6 lg:h-12"
                />
                <div className="flex flex-col lg:flex-row gap-x-2 min-w-0 gap-y-1">
                  <span className="text-xs font-bold truncate max-w-[12rem]">
                    {item.name}
                  </span>
                  <span className="text-xs">{getSizeDimension(item.size)}</span>
                </div>
              </div>
              <MdDeleteOutline
                className="text-xl cursor-pointer"
                onClick={() => handleFileRemove(item)}
              />
            </div>
          ))}
        <div
          className="border border-dotted text-xs lg:text-sm border-gray/20 p-5 rounded text-gray/50 hover:border-gray hover:text-gray cursor-pointer"
          onClick={() => handleUploadClick()}
        >
          + Click to add new file
        </div>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={(e) => handleFileUpload(e)}
          style={{ display: "none" }}
          multiple
        />
      </div>
      <div className=" border-l border-l-gray/10 px-5 w-2/3 relative">
        <h1 className="font-bold underline decoration-primary decoration-4 text-xl">
          Metadata
        </h1>
        {/* metadata form and date */}
        <div className="mt-2">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-2">
              <Input
                label={"Description"}
                placeholder={"This file is about..."}
                value={selectedFile?.description || ""}
                onChange={(e) =>
                  handleAddDescription(selectedFile.name, e.target.value)
                }
              />
              
            </div>
            <InputTags
              label={"Tags"}
              description={
                "Add tags to make this file easier to find. Press Enter to add tag (format: language=italian)"
              }
              existingTags={selectedFile?.tags || []}
              onUpdate={(t) => handleTags(t)}
            />
          </div>

          {/* <div className="absolute bottom-0 left-5">
            <BaseButton value={"Save"} onClick={() => handleSaveMetadata()} />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ListUploadedFile;
