import React, { useState } from "react";
import Select from "react-select";

function SelectBase({
  label,
  options,
  onChange,
  field,
  value,
  bgColor,
  styleClass,
}) {
  const handleChange = (e) => {
    onChange(e);
  };

  // console.log("options: ", options)

  return (
    <div className={`flex flex-col gap-y-2 ${styleClass}`}>
      <label className="font-bold uppercase text-xs">{label}</label>
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        value={value}
        onChange={(e) => handleChange(e)}
        placeholder="Select Option"
        options={options}
        menuPlacement="top"
      />
    </div>
  );
}

Select.defaultProps = {
  bgColor: "bg-base",
};

export default SelectBase;
