import React from "react";
import NotFoundImg from "./404.jpg";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen bg-[#5CB6C4] flex items-center justify-center flex-col">
      <img src={NotFoundImg} alt="404 page not found" className="h-3/4" />
      <h1
        className="text-2xl font-bold text-white underline cursor-pointer"
        onClick={() => navigate("/")}
      >
        Back to Home
      </h1>
    </div>
  );
}

export default PageNotFound;
