import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createChatbot,
  uploadDatasources,
  deleteChatbot,
  setUserPermission,
  fetchCompanyConnectors,
} from "../../services/api/ManagerService";
import { executePipeline } from "../../services/api/PipelineService";
// import Input from "../../components/Forms/Input";
import { Input } from "@/components/ui/input";

import { GrScan } from "react-icons/gr";
import FreeText from "../../components/ConnectorsInstruction/FreeText/FreeText";
import UsersPermissionsTable from "../../components/Permissions/UsersPermissionsTable";
import InfoBanner from "../../components/Banner/InfoBanner";
import BaseButton from "../../components/Buttons/BaseButton";
import {
  useChatbotCountState,
  useNotValidFilesState,
} from "../../states/useStates";
import { useRecoilValue } from "recoil";
import { companyConstraintsState } from "../../states/atoms";
import { addTagsToDatasource } from "../../services/api/ManagerService";
import StructuredText from "../../components/ConnectorsInstruction/StructuredText/StructuredText";
import PageLayout from "../PageLayout";
import Connector from "../../components/Connectors/Connector";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import {
  connectorErrorsState,
  isConnectorValidState,
  currentUserState,
} from "../../states/atoms";
import ModalBuilder from "@/components/Modal/ModalBuilder";
import BuilderToolsTable from "@/components/Table/BuilderToolsTable";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

function Builder() {
  const navigate = useNavigate();
  const user = useRecoilValue(currentUserState);
  const [notValidFiles, setNotValidFiles] = useNotValidFilesState();
  const [chatbotCount, setChatbotCount] = useChatbotCountState();
  const [connectorList, setConnectorList] = useState([
    {
      name: "PDF",
      alias: "free-text",
      icon: (
        <img
          src={process.env.PUBLIC_URL + "/images/file_type/pdf.png"}
          className="h-8 lg:h-16"
          alt="free-text"
        />
      ),
      selected: true,
      validFormat: [
        ".doc",
        ".docx",
        ".enex",
        ".epub",
        ".html",
        ".xhtml",
        ".md",
        ".odt",
        ".pdf",
        ".ppt",
        ".pptx",
        ".txt",
      ],
      instructions: (
        <FreeText
          formatExplanation="TXT, HTML, PDF, DOCX, PPTX, MD..."
          onUpload={(files) => setFiles(files)}
          validFormat={[
            ".doc",
            ".docx",
            ".enex",
            ".epub",
            ".html",
            ".xhtml",
            ".md",
            ".odt",
            ".pdf",
            ".ppt",
            ".pptx",
            ".txt",
          ]}
        />
      ),
      available: true,
    },
    {
      name: "CSV/Excel",
      alias: "structured-text",
      icon: (
        <img
          src={process.env.PUBLIC_URL + "/images/csvfiles.png"}
          className="h-8 lg:h-16"
          alt="structured-text"
        />
      ),
      selected: false,
      instructions: (
        <StructuredText
          formatExplanation="CSV, EXCEL"
          onUpload={(files) => setFiles(files)}
          validFormat={[".csv", ".xls", ".xlsx"]}
        />
      ),
      available: true,
    },
    {
      name: "OCR Tables",
      alias: "ocr-tables",
      icon: <GrScan className="text text-5xl" />,
      selected: false,
      instructions: (
        <FreeText
          formatExplanation="Upload scanned PDF with tables inside, the connector will extract all the tables"
          onUpload={(files) => setFiles(files)}
        />
      ),
      available: true,
    },
  ]);

  const [justMarketingConnectors, setJustMarketingConnectors] = useState([
    {
      name: "Database",
      alias: "database",
      icon: (
        <img
          src={process.env.PUBLIC_URL + "/images/file_type/sql.png"}
          className="h-8 lg:h-16"
          alt="sql"
        />
      ),
      selected: false,
      instructions: (
        <FreeText
          formatExplanation="Upload scanned PDF with tables inside, the connector will extract all the tables"
          onUpload={(files) => setFiles(files)}
        />
      ),
      available: false,
    },
    {
      name: "Web Pages",
      alias: "web-pages",
      icon: (
        <img
          src={process.env.PUBLIC_URL + "/images/file_type/url.png"}
          className="h-8 lg:h-16"
          alt="web-pages"
        />
      ),
      selected: false,
      instructions: (
        <FreeText
          formatExplanation="Upload scanned PDF with tables inside, the connector will extract all the tables"
          onUpload={(files) => setFiles(files)}
        />
      ),
      available: false,
    },
    {
      name: "SharePoint",
      alias: "sharepoint",
      icon: (
        <img
          src={process.env.PUBLIC_URL + "/images/file_type/sharepoint.png"}
          className="h-8 lg:h-16"
          alt="sharepoint"
        />
      ),
      selected: false,
      instructions: (
        <FreeText
          formatExplanation="Upload scanned PDF with tables inside, the connector will extract all the tables"
          onUpload={(files) => setFiles(files)}
        />
      ),
      available: false,
    },
  ]);

  const [chatbotName, setChatbotName] = useState(`chatto-${Date.now()}`);
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [metadata, setMetadata] = useState([]);
  const [permissions, setPermissions] = useState(null);
  const [chatbotCreated, setChatbotCreated] = useState(null);
  const [connectors, setConnectors] = useState(connectorList);
  const [connector, setConnector] = useState(connectors[0]);
  const [connectorInstruction, setConnectorInstruction] = useState(
    connectors[0].instructions
  );
  const [progressValue, setProgressValue] = useState(null);
  const [progressText, setProgressText] = useState(null);
  const [progressColor, setProgressColor] = useState(null);
  const [showShadow, setShowShadow] = useState(false);
  const [config, setConfig] = useState({
    user_uuid: localStorage.getItem("sub"),
    connector_type: "free-text",
    embeddings_model_provider: "openai",
    embeddings_model: "text-embedding-ada-002",
  });
  const [runCompleted, setRunCompleted] = useState(false);
  const [totalTokens, setTotalTokens] = useState(0);
  const [companyConnectors, setCompanyConnectors] = useState([]);
  const [chatbotTools, setChatbotTools] = useState([]);
  const companyConstraints = useRecoilValue(companyConstraintsState);

  const [isConnectorValid, setIsConnectorValid] = useRecoilState(
    isConnectorValidState
  );

  const [connectorErrors, setConnectorErrors] =
    useRecoilState(connectorErrorsState);

  const handleSelectedConnector = (name) => {
    const updateConnectorStatus = connectors.map((obj) => {
      if (obj.name === name && obj.available) {
        setConnectorInstruction(obj.instructions);
        setConnector(obj);
        return { ...obj, selected: true };
      } else {
        return { ...obj, selected: false };
      }
    });
    setConnectors(updateConnectorStatus);
    setFiles([]);
  };

  // const handleAdvancedSettings = (model, vectorestore) => {
  //   if (vectorestore !== null) {
  //     setConfig({ ...config, vectorestore: vectorestore.name.toLowerCase() });
  //   } else {
  //     setConfig({ ...config, vectorestore: "elasticsearch" });
  //   }

  //   if (model !== null) {
  //     setConfig({
  //       ...config,
  //       embeddings_model: model.provider.toLowerCase(),
  //       embeddings_model_name: model.model,
  //     });
  //   } else {
  //     setConfig({
  //       ...config,
  //       embeddings_model: "openai",
  //       embeddings_model_name: "text-embedding-ada-002",
  //     });
  //   }
  // };

  // console.log("selected connector", connector);

  const configureFileTags = (connector) => {
    let files_tags = [];

    // if (connector === "free-text") {
    //   files.map((file) => {
    //     let tags = {};
    //     const description = file.description;

    //     file.tags.forEach((tag) => {
    //       const [key, value] = tag.text.split("=");
    //       if (key && value) {
    //         tags[key] = value;
    //       }
    //     });
    //     tags["description"] = description;
    //     files_tags.push(tags);
    //   });
    // } else
    if (connector === "structured-text") {
      files.map((file) => {
        let tags = {};
        const description = file.table_description;
        tags["description"] = description;
        tags["column_description"] = file.tags;
        files_tags.push(tags);
      });
    }

    return files_tags;
  };

  const handleChatbotCreation = async () => {
    if (!validateGenericFields()) {
      return;
    }

    if (!isConnectorValid) {
      let errMsg = "";
      connectorErrors.forEach((error) => {
        errMsg += error + "\n";
      });
      toast.error(errMsg);
      return;
    }

    setShowShadow(true);
    setProgressValue(10);
    setProgressText("Initializing the chatbot...");
    setProgressColor("light-blue");

    let fileTags = [];
    if (config.connector_type === "free-text") {
      fileTags = configureFileTags("free-text");
    } else if (config.connector_type === "structured-text") {
      fileTags = configureFileTags("structured-text");
    } else {
      fileTags = [];
    }

    // config the chatbot and return the response
    const config_res = await createChatbot(config).catch((err) => {
      setProgressText(
        "Failed during chatbot creation: " + err.response.data.detail
      );
      setProgressColor("red");
    });

    if (config_res) {
      setChatbotCreated(config.data);
      setProgressValue(50);
      setProgressText("Uploading files...");

      const res_uploadDatasource = await uploadDatasources(
        chatbotName,
        files
      ).catch((err) => {
        setProgressText(
          "Failed during uploading files: " + err.response.data.detail
        );
        setProgressColor("red");
        deleteChatbot(chatbotName);
      });

      if (fileTags.length > 0) {
        console.log("Add tags");
        res_uploadDatasource.map((item, index) => {
          addTagsToDatasource(chatbotName, item.id, {
            tags: fileTags[index],
          });
        });
      }

      setProgressValue(70);

      const pipelinePayload = {
        chatbot_name: chatbotName,
        company_id: localStorage.getItem("company_id"),
      };
      await executePipeline(config.connector_type, pipelinePayload).catch(
        (err) => {
          console.log(err);
          setProgressText("Failed during embeddings: " + err);
          setProgressColor("red");
          deleteChatbot(chatbotName);
        }
      );

      setProgressValue(90);
      setProgressText("Set permissions...");

      await setUserPermission(chatbotName, {
        users: config.permissions ? config.permissions : [],
      }).catch((err) => console.log(err));

      setRunCompleted(true);
      setChatbotCount(chatbotCount + 1);
    }
  };

  const validateGenericFields = () => {
    if (chatbotName.length <= 0) {
      toast.error("Chatbot Name is required to create a new chatbot.");
      return false;
    }

    if (description.length <= 0) {
      toast.error("Chatbot Name is required to create a new chatbot.");
      return false;
    }

    if (files.length <= 0) {
      toast.error("No files uploaded.");
      return false;
    }

    if (chatbotCount >= companyConstraints.max_chatbots) {
      toast.error("Comapny's max chatbot number reached.");
      return false;
    }

    return true;
  };

  useEffect(() => {
    const selected = connectors.filter((item) => item.selected === true)[0];
    if (selected) {
      setConfig({ ...config, connector_type: selected.alias });
    } else {
      setConfig({ ...config, connector_type: null });
    }
  }, [connectors]);

  useEffect(() => {
    setConfig({
      ...config,
      chatbot_name: chatbotName,
      chatbot_description: description,
      tools: chatbotTools,
    });
  }, [chatbotName, description, chatbotTools]);

  useEffect(() => {
    setProgressValue(80);
    setProgressColor("light-blue");
    setProgressText("Setting the permissions...");

    new Promise((resolve) => setTimeout(resolve, 2000));

    setProgressValue(100);
    setProgressText("Complete!");
    setProgressColor("green");
    // updateChatbotCostMetrics();
    setIsConnectorValid(false);
  }, [runCompleted]);

  useEffect(() => {
    const getCompanyConnectors = async () => {
      const res = await fetchCompanyConnectors();
      setCompanyConnectors(res);
    };

    getCompanyConnectors();
  }, []);

  useEffect(() => {
    if (companyConnectors.length > 0) {
      const companyConnectorsAlias = companyConnectors.map(
        (item) => item.connector_name
      );
      let updateConnectorList = [];
      connectorList.map((item) => {
        // check if item.alias is inside companyConnectorsAlias
        if (companyConnectorsAlias.includes(item.alias)) {
          updateConnectorList.push(item);
        }
      });
      setConnectors(updateConnectorList);
    } else {
      setConnectors(connectorList.filter((item) => item.name === "PDF"));
    }
  }, [companyConnectors]);

  if (!user.is_admin && !user.is_builder) {
    return navigate("/chat");
  }

  return (
    <PageLayout>
      <div className="pr-4 pl-4 lg:pl-0">
        <div className="py-3">
          <span className="text-grayCustom/50">
            Builder / Create a new chatbot
          </span>
        </div>
        <div className="py-5">
          <h2 className="text-xl font-bold mb-5">Chatbot Informations</h2>
          <div className="flex flex-col lg:flex-row gap-y-4 gap-x-10">
            <div className="w-full">
              <Input
                className="focus:outline-none focus:ring-transparent focus:border-primary"
                type="text"
                placeholder="Chatbot Name*"
                value={chatbotName}
                onChange={(e) => setChatbotName(e.target.value)}
                onBlur={() => {
                  if (chatbotName.length <= 0) {
                    toast.error("Name is required");
                  }
                }}
              />
              <div className="text-xs text-gray-500">
                A good name will help Chatto to correctly identify the chatbot
                during the Q&A.
              </div>
            </div>
            <div className="w-full">
              <Input
                className="focus:outline-none focus:ring-transparent focus:border-primary"
                type="text"
                placeholder="Description*"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onBlur={() => {
                  if (description.length <= 0) {
                    toast.error("Description is required");
                  }
                }}
              />
              <div className="text-xs text-gray-500">
                A good description will help Chatto to correctly identify the
                chatbot during the Q&A.
              </div>
            </div>
          </div>
        </div>
        {/* q2 - choose the connector */}
        <div className={`py-2 lg:mt-5`}>
          <h2 className="text-xl font-bold mb-5">Select the Connector *</h2>
          <div className="flex gap-x-5">
            {connectors.map((connector, index) => {
              return (
                <Connector
                  key={index}
                  name={connector.name}
                  icon={connector.icon}
                  onClick={(name) => handleSelectedConnector(name)}
                  selected={connector.selected}
                  available={connector.available}
                />
              );
            })}
            {justMarketingConnectors.map((connector, index) => {
              return (
                <Connector
                  key={index}
                  name={connector.name}
                  icon={connector.icon}
                  onClick={(name) => handleSelectedConnector(name)}
                  selected={connector.selected}
                  available={connector.available}
                />
              );
            })}
          </div>
          <div className={`${connectorInstruction ? "mt-5" : ""}`}>
            {connectorInstruction ? connectorInstruction : ""}
          </div>
        </div>
        {/* set tools associated to chatbot */}
        {connector.alias === "free-text" && (
          <div className="lg:mt-10 lg:mb-10">
            <h2 className="text-xl font-bold mb-5">
              Set Tools associated to chatbot
            </h2>
            <BuilderToolsTable onUpdate={(t) => setChatbotTools(t)} />
          </div>
        )}
        {/* q3 - Set Permissions */}
        <div className="lg:mt-10 lg:mb-10">
          <h2 className="text-xl font-bold mb-5">Set Permissions</h2>
          <InfoBanner>
            <p>
              Secure your chatbot allowing only people assigning{" "}
              <span className="font-bold">CHAT ACCESS</span> to chat with it.
            </p>
            <p>
              By default, <span className="font-bold">ADMIN</span> and{" "}
              <span className="font-bold">BUILDER</span> can chat with the
              chatbot.
            </p>
          </InfoBanner>
          <UsersPermissionsTable
            onUpdate={(permissions) =>
              setConfig({ ...config, permissions: permissions })
            }
          />
        </div>
        <div className="mt-5 flex justify-center py-2">
          <ModalBuilder
            onOpen={handleChatbotCreation}
            text={progressText}
            progressColor={progressColor}
            chatbot={chatbotName}
            isValid={isConnectorValid}
            progressValue={progressValue}
          />
        </div>
      </div>
    </PageLayout>
  );
}

export default Builder;
