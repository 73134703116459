import React, { useState, useEffect } from "react";
import { BiHappyHeartEyes, BiSad, BiCopy } from "react-icons/bi";
import Markdown from "../Markdown/Markdown";
import BaseButton from "../Buttons/BaseButton";
import { SyncLoader } from "react-spinners";
function BotMessage({
  message,
  sources,
  width,
  chatbot,
  isLoading,
  onPositiveFeedback,
  onNegativeFeedback,
  message_id,
}) {
  const [expectedAnswer, setExpectedAnswer] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isNegativeFeedback, setIsNegativeFeedback] = useState(false);
  const [isPositiveFeedback, setIsPositiveFeedback] = useState(false);

  const handleSendPositiveFeedback = () => {
    setIsPositiveFeedback(true);
    onPositiveFeedback({
      message_id: message_id,
      answer: message,
      is_correct: true,
      source: "chat",
      chatbot: chatbot,
    });
  };

  const handleNegativeFeedback = () => {
    setIsNegativeFeedback(true);
    setShowModal(false);
    onNegativeFeedback({
      message_id: message_id,
      answer: message,
      is_correct: false,
      expected_answer: expectedAnswer,
      source: "chat",
      chatbot: chatbot,
    });
  };

  return (
    <>
      {showModal ? (
        <div className="absolute bg-white w-full h-fit top-10 rounded-xl shadow p-10 z-50">
          <div className="flex justify-end">
            <span
              className="cursor-pointer"
              onClick={() => setShowModal(false)}
            >
              X
            </span>
          </div>
          <h2 className="text-xl font-bold mb-5">Expected Answer</h2>
          <textarea
            className="w-full border border-gray/30 h-fit outline-none p-5"
            value={expectedAnswer}
            onChange={(e) => setExpectedAnswer(e.target.value)}
          />
          <div className="flex justify-end mt-5">
            <BaseButton
              value={"Send Feedback"}
              onClick={() => handleNegativeFeedback()}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="flex mb-5 md:mt-5 w-full">
        <div
          className={`bg-[#EBEBEB] text-gray p-5 rounded-xl shadow ${width} `}
        >
          <div className="w-fit mb-2">
            <div className="bg-primary text-white font-bold px-2 rounded text-xs">
              {chatbot}
            </div>
          </div>

          {isLoading ? (
            <SyncLoader color="#50ADB4" size={7} className="mt-8" />
          ) : (
            <div className="w-full">
              <p className="prose max-w-none prose-table:p-2 prose-thead:bg-primary prose-thead:border prose-tr:border prose-th:border prose-td:border">
                <Markdown text={message} />
              </p>
            </div>
          )}

          <div className="flex justify-end mb-1 mt-4 gap-x-2">
            {!isLoading && (
              <>
                <BiHappyHeartEyes
                  className={`text-xl hover:text-primary cursor-pointer ${
                    isPositiveFeedback ? "text-primary" : ""
                  }`}
                  onClick={handleSendPositiveFeedback}
                />
                <BiSad
                  className={`text-xl hover:text-primary cursor-pointer ${
                    isNegativeFeedback ? "text-primary" : ""
                  }`}
                  onClick={() => setShowModal(true)}
                />
                <BiCopy
                  className="text-xl hover:text-primary cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(message);
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BotMessage;
