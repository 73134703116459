import React from "react";
import Markdown from "../Markdown/Markdown";

function UserMessageV2({ message }) {
  return (
    <div className="bg-primary text-white p-2 rounded-lg">
      <Markdown text={message} />
    </div>
  );
}

export default UserMessageV2;
