import { useCallback, useState, useEffect } from "react";
import { MdDeleteOutline } from "react-icons/md";
import {
  extractFileExtensions,
  getSizeDimension,
  sliceFilename,
} from "../../services/utils";
import Input from "../../components/Forms/Input";
import InputTagsDropzone from "./InputTagsDropzone";
import "./Dropzone.css";

const FreeTextFilesList = ({ files, setFiles }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [description, setDescription] = useState("");

  const handleAddDescription = (target, description) => {
    setDescription(description);
    const updatedFiles = files.map((item) => {
      if (item.name === target) {
        item.description = description;
      }
      return item;
    });
    setFiles(updatedFiles);
  };

  const addMetadata = (target, metadata) => {
    const updatedFiles = files.map((item) => {
      if (item.name === target) {
        item.tags = metadata;
      }
      return item;
    });

    setFiles(updatedFiles);
  };

  const handleTags = (tags) => {
    if (selectedFile) {
      addMetadata(selectedFile.name, tags);
    }
  };

  const removefile = (name) => {
    setFiles((files) => files.filter((file) => file.name !== name));
    setSelectedFile(files[0]);
  };

  useEffect(() => {
    if (selectedFile === undefined && files.length > 0) {
      setSelectedFile(files[0]);
    } else {
      setSelectedFile(selectedFile);
    }
  }, [files, selectedFile]);

  return (
    <div>
      {files.length > 0 && (
        <div className=" max-h-[30vh] rounded mt-6 flex gap-2 ">
          <div className="flex flex-col w-1/2 overflow-y-auto overflow-x-hidden pr-4  gap-4 ">
            {files.map((file) => (
              <div
                className={`${
                  selectedFile && selectedFile.name === file.name
                    ? "bg-primary text-white"
                    : ""
                } p-2 border border-gray/20 rounded flex items-center justify-between cursor-pointer hover:bg-primary/50 hover:text-white pr-8`}
                key={file.name}
                onClick={() => setSelectedFile(file)}
              >
                <div className="flex items-center gap-2">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/file_type/" +
                      extractFileExtensions(file.name, true) +
                      ".png"
                    }
                    alt="file extension icon"
                    className="h-6 lg:h-12"
                  />
                  <div className="flex flex-col lg:flex-row gap-x-2 min-w-0 gap-y-1">
                    <span className="text-xs font-bold whitespace-nowrap  w-fit">
                      {sliceFilename(file.name, 20)}
                    </span>
                    <span className="text-xs">
                      {getSizeDimension(file.size)}
                    </span>
                  </div>
                </div>
                <MdDeleteOutline
                  className="text-xl cursor-pointer hover:text-gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    removefile(file.name);
                  }}
                />
              </div>
            ))}
          </div>
          <div className="border-l border-l-gray/10 px-5 w-2/3 relative overflow-y-auto">
            <h1 className="font-bold underline decoration-primary decoration-4 text-xl">
              Metadata
            </h1>
            {/* metadata form and date */}
            <div className="mt-2">
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-2">
                  <Input
                    label={"Description"}
                    placeholder={"This file is about..."}
                    value={selectedFile?.description || ""}
                    onChange={(e) =>
                      handleAddDescription(selectedFile.name, e.target.value)
                    }
                  />
                </div>
                <InputTagsDropzone
                  label={"Tags"}
                  description={
                    "Add tags to make this file easier to find. Press Enter to add tag (format: language=italian)"
                  }
                  existingTags={selectedFile?.tags || []}
                  onUpdate={(t) => handleTags(t)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FreeTextFilesList;
