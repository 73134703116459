import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { fetchChatbots, sendFeedback } from "../../services/api/ManagerService";
import { fetchUserChatbots } from "@/services/api/UsersService";
import { AiOutlineRobot } from "react-icons/ai";
import { CiSearch } from "react-icons/ci";

function DialogSelectChatbot({ onChatbotSelect, currentChatbot }) {
  const [chatbotList, setChatbotList] = useState([]);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fn = async () => {
      const chatbots = await fetchUserChatbots().catch((e) => {
        console.log(e);
      });
      setChatbotList(chatbots);
    };
    fn();
  }, []);

  const handleSelection = (chatbot) => {
    onChatbotSelect(chatbot.chatbot_name);
    setOpen(false);
    setSearch("");
  };

  const filteredChatbots = chatbotList.filter((chatbot) =>
    chatbot.chatbot_name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className={`flex items-center text-sm w-fit gap-2 ${
          currentChatbot ? "opacity-100 text-primary" : "opacity-30"
        }  hover:opacity-100 cursor-pointer`}
      >
        <AiOutlineRobot />
        {currentChatbot ? (
          <span>{currentChatbot}</span>
        ) : (
          <span>Select chatbot</span>
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-primary">
            Select a specific chatbot to start a conversation
          </DialogTitle>
          <DialogDescription>
            <div className="flex gap-2 items-center w-full justify-center border-b mt-2">
              <CiSearch />
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search..."
                className="p-2 w-full outline-none border-none bg-transparent"
              />
            </div>
            <div className="mt-3 opacity-50 text-xs">Available chatbots</div>

            <div className="flex flex-col gap-2 mt-2">
              {filteredChatbots.length === 0 ? (
                <div className="mt-2">
                  <p>Seems like you don't have any chatbot yet.</p>
                  <p>
                    If you are a builder you can create one, otherwise ask your
                    administrator to assign you a chatbot.
                  </p>
                </div>
              ) : (
                <>
                  <div
                    className="flex gap-1 cursor-pointer flex-col hover:bg-primary hover:text-white p-2 rounded"
                    onClick={() => handleSelection("")}
                  >
                    <h3 className="font-bold">All chatbots</h3>
                    <p className="text-xs">
                      Chatto autonomously selects the most suitable knowledge
                      base to respond.
                    </p>
                  </div>
                  {filteredChatbots.map((chatbot, i) => (
                    <div
                      key={i}
                      className={`flex gap-1 cursor-pointer flex-col hover:bg-primary hover:text-white p-2 rounded ${
                        currentChatbot === chatbot.chatbot_name
                          ? "bg-primary text-white"
                          : ""
                      }`}
                      onClick={() => handleSelection(chatbot)}
                    >
                      <h3 className="font-bold">{chatbot.chatbot_name}</h3>
                      <p className="text-xs">{chatbot.chatbot_description}</p>
                    </div>
                  ))}
                </>
              )}
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export default DialogSelectChatbot;
