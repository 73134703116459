import React, { useState } from "react";
import ListUploadedFile from "./ListUploadedFile";
import UploadZone from "../UploadZone";

function FreeText({ formatExplanation, onUpload, validFormat }) {
  const [files, setFiles] = useState([]);

  const handleFileChange = (newFiles) => {
    setFiles(newFiles ? [...files, ...newFiles] : []);
    onUpload(newFiles ? [...files, ...newFiles] : []);
  };

  const handleFileRemove = (target) => {
    const newFiles = files.filter((file) => file.name !== target.name);
    setFiles(newFiles);
    onUpload(newFiles);
  };

  return (
    <div className="mb-5">
      {files.length > 0 ? (
        <ListUploadedFile
          handleFileChange={handleFileChange}
          handleFileRemove={handleFileRemove}
          setFiles={setFiles}
          files={files}
          validFormat={validFormat}
        />
      ) : (
        <UploadZone
          connectorType="free-text"
          formatExplanation={formatExplanation}
          handleFileChange={handleFileChange}
          validFormat={validFormat}
        />
      )}
    </div>
  );
}

export default FreeText;
