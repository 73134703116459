import axios from "axios";

const SuperadminService = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_ENDPOINT + "/api/v1/superadmin",
    headers: {
      "Content-Type": "application/json",
      "X-XSS-Protection": "X-XSS-Protection: 1; mode=block",
      "X-Frame-Options": "SAMEORIGIN",
      "Strict-Transport-Security": "max-age=10000000",
      "Content-Security-Policy": "default-src 'self'; child-src 'none';",
      "Referrer-Policy": "no-referrer",
      "X-Content-Type-Options": "nosniff",
    },
  };

  let instance = axios.create(defaultOptions);

  return instance;
};

export default SuperadminService();

const loginAsSuperadmin = async (email, password) => {
  const payload = {
    email: email,
    password: password,
  };

  try {
    const res = await SuperadminService().post("/login", payload);
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

const fetchCompanies = async () => {
  try {
    const res = await SuperadminService().get("/companies", {
      headers: {
        Authorization:
          "Bearer " + localStorage.getItem("localAuthorizationSuperAdmin"),
      },
    });
    if (res) {
      return res.data;
    }
  } catch (err) {
    return err.response;
  }
};

const deleteCompany = async (id) => {
  try {
    const res = await SuperadminService().delete("/companies/" + id, {
      headers: {
        Authorization:
          "Bearer " + localStorage.getItem("localAuthorizationSuperAdmin"),
      },
    });
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

const fetchAvailableConnectors = async () => {
  try {
    const res = await SuperadminService().get("/connectors", {
      headers: {
        Authorization:
          "Bearer " + localStorage.getItem("localAuthorizationSuperAdmin"),
      },
    });
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

const createCompany = async (payload) => {
  try {
    const res = await SuperadminService().post("/companies", payload, {
      headers: {
        Authorization:
          "Bearer " + localStorage.getItem("localAuthorizationSuperAdmin"),
      },
    });
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

const createCompanyConstraint = async (company_id, payload) => {
  try {
    const res = await SuperadminService().post(
      "/companies/" + company_id + "/constraints",
      payload,
      {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("localAuthorizationSuperAdmin"),
        },
      }
    );
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

const associateConnectorToCompany = async (company_id, payload) => {
  try {
    const res = await SuperadminService().post(
      "/companies/" + company_id + "/connectors",
      payload,
      {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("localAuthorizationSuperAdmin"),
        },
      }
    );
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export {
  loginAsSuperadmin,
  fetchCompanies,
  deleteCompany,
  fetchAvailableConnectors,
  createCompany,
  createCompanyConstraint,
  associateConnectorToCompany,
};
