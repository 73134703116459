import React, { useState } from "react";
import Logo from "../Sidebar/img/Logo.png";
import DrawerSidebar from "../Sidebar/DrawerSidebar";

const Navbar = () => {
  return (
    <>
      <div className="p-4 sticky top-0 bg-base z-50 md:hidden">
        <div className="flex justify-between gap-4">
          <DrawerSidebar />
          <img src={Logo} alt="chatto logo" className="transition-all h-12" />
        </div>
      </div>
    </>
  );
};

export default Navbar;
