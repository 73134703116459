import React, { useState } from "react";
import DefaultProgressBar from "../ProgressBar/DefaultProgressBar";
import { useNavigate } from "react-router-dom";
import { MdLogout, MdEmail } from "react-icons/md";
import { twMerge } from "tailwind-merge";
const UserWithStats = ({
  fullname,
  minimize,
  company,
  totalSize,
  companyConstraints,
  totalSizePercentage,
  chatbotCount,
  queryCount,
  openSidebar,
  isBaseUser,
}) => {
  const navigate = useNavigate();
  const [showBox, setShowBox] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("localAuthorization");
    localStorage.removeItem("fullname");
    navigate("/");
    window.location.reload(true);
  };
  return (
    <div className="relative z-50">
      <div
        className="flex gap-x-3 items-center cursor-pointer"
        onClick={() => setShowBox(!showBox)}
      >
        <div className="rounded-full bg-primary p-2">
          <span className="font-bold text-white">
            {fullname.replace(/[^A-Z]/g, "")}
          </span>
          <span></span>
        </div>
        {minimize ? (
          ""
        ) : (
          <div className="flex flex-col">
            <div className="text-xs">{fullname} </div>
            <div className="text-xs font-bold">{company}</div>
          </div>
        )}
      </div>

      {/* options on click */}
      {showBox && (
        <div
          className={twMerge(
            "bg-white px-3 py-3 shadow absolute -top-[16rem] w-[12rem] text-primary rounded-lg border border-primary",
            openSidebar ? "-right-[3rem]" : "-right-[9rem]"
          )}
        >
          <div>
            <div className="border border-primary p-4 rounded border-dotted mb-4">
              {/* usage report */}
              <div className="flex flex-col gap-4">
                {isBaseUser && (
                  <>
                    <div>
                      <h5 className="text-xs font-bold mb-2">USAGE SIZE</h5>
                      <div className="w-full flex justify-between text-xs mb-1">
                        <span>{Math.round(totalSize)}MB</span>
                        <span>
                          {Math.round(
                            companyConstraints.max_datasource_size_mb
                          )}
                          MB
                        </span>
                      </div>
                      <div className="w-full">
                        <DefaultProgressBar
                          styleClass={`${
                            totalSizePercentage === 100
                              ? "bg-red-500"
                              : "bg-primary"
                          }`}
                          percentage={totalSizePercentage}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <h5 className="text-xs font-bold">CHATBOTS:</h5>
                      <span>
                        {chatbotCount} / {companyConstraints.max_chatbots}
                      </span>
                    </div>
                  </>
                )}

                <div className="flex items-center justify-between">
                  <span className="text-xs font-bold">DAILY QUERIES:</span>
                  <span className="ml-4">
                    {companyConstraints.max_daily_query - queryCount}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <ul className="text-xs flex flex-col gap-y-2">
            <li className="flex gap-x-2 items-center cursor-pointer hover:underline">
              <MdEmail className="" />
              Contact Us
            </li>
            <li
              className="flex gap-x-2 items-center cursor-pointer hover:underline"
              onClick={handleLogout}
            >
              <MdLogout className="" />
              Logout
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserWithStats;
