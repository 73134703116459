import React, { useState, useEffect } from "react";
import { fetchChatbots } from "../../services/api/ManagerService";
import NoRecordFound from "../../components/NoRecordFound/NoRecordFound";
import KnowledgeBaseCards from "../../components/KnowledgeBase/KnowledgeBaseCards";
import Omnisearch from "../../components/Omnisearch/Omnisearch";
import { useRecoilValue } from "recoil";
import { currentUserState } from "../../states/atoms";
import PageLayout from "../PageLayout";
import { useNavigate } from "react-router-dom";

function ViewKnowledgeBase() {
  const [datastores, setDatastores] = useState([]);
  const [filteredDatastore, setFilteredDatastore] = useState([]);
  const user = useRecoilValue(currentUserState);
  let navigate = useNavigate();

  useEffect(() => {
    const getDatastores = async () => {
      const data = await fetchChatbots().catch((e) => console.log(e));
      // console.log(data);
      setDatastores(data);
      setFilteredDatastore(data);
    };
    if (user) {
      getDatastores();
    }
  }, [user]);

  const handleSearch = (target) => {
    if (target !== "") {
      const filtered = datastores.filter((item) =>
        item.chatbot_name.toLowerCase().includes(target.toLowerCase())
      );
      setFilteredDatastore(filtered);
    } else {
      setFilteredDatastore(datastores);
    }
  };

  useEffect(() => {
    setFilteredDatastore(datastores);
  }, [datastores]);

  if (!user.is_admin && !user.is_builder) {
    return navigate("/chat");
  }

  console.log(filteredDatastore);

  return (
    <PageLayout>
      <div className="pr-4 pl-2 lg:pl-0 pb-2 overflow-auto">
        {/* <div className="py-3">
        <span className="text-gray/50">Builder / Knowledge Base</span>
      </div> */}
        <div className="md:mt-10 pr-2">
          <h1 className="text-2xl font-bold">Chatbots</h1>
        </div>
        <div className="mt-2 w-full lg:w-1/2 lg:mt-5">
          <Omnisearch onSearch={(t) => handleSearch(t)} />
        </div>
        <div className="mt-5 lg:mt-10">
          {filteredDatastore.length > 0 ? (
            // ? <KnowledgeBaseTable datastores={datastores} onDelete={(items) => setDatastores(items)} />
            <KnowledgeBaseCards datastores={filteredDatastore} />
          ) : (
            <NoRecordFound />
          )}
        </div>
      </div>
    </PageLayout>
  );
}

export default ViewKnowledgeBase;
