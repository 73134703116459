import axios from "axios";

const ManagerService = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_ENDPOINT + "/api/v1/manager",
    headers: {
      "Content-Type": "application/json",
      "X-XSS-Protection": "X-XSS-Protection: 1; mode=block",
      "X-Frame-Options": "SAMEORIGIN",
      "Strict-Transport-Security": "max-age=10000000",
      "Content-Security-Policy": "default-src 'self'; child-src 'none';",
      "Referrer-Policy": "no-referrer",
      "X-Content-Type-Options": "nosniff",
      Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
    },
  };

  let instance = axios.create(defaultOptions);

  return instance;
};

export default ManagerService();

const fetchChatbots = async () => {
  const res = await ManagerService().get(
    `/companies/${localStorage.getItem("company_id")}/chatbots`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
      },
    }
  );
  if (res.status === 200) {
    let chatbots = [];
    res.data.map((item) => {
      chatbots.push({
        ...item,
        label: item.chatbot_name,
        value: item.chatbot_name,
      });
    });
    return chatbots;
  }
};

const fetchChatbotsTotalSizeInMb = async () => {
  const res = await ManagerService().get(
    `/companies/${localStorage.getItem(
      "company_id"
    )}/metrics/datasource/total-size`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
      },
    }
  );
  if (res.status === 200) {
    return res.data;
  }
};

const fetchDatasources = async (chatbot_name) => {
  const res = await ManagerService().get(
    `/companies/${localStorage.getItem(
      "company_id"
    )}/chatbots/${chatbot_name.toLowerCase()}/datasources`
  );
  if (res.status === 200) {
    return res.data;
  }
};

const fetchChatbotTools = async (chatbot_name) => {
  const res = await ManagerService().get(
    `/companies/${localStorage.getItem(
      "company_id"
    )}/chatbots/${chatbot_name.toLowerCase()}/tools`
  );
  if (res.status === 200) {
    return res.data;
  }
};

const createChatbot = async (payload) => {
  const res = await ManagerService().post(
    `/companies/${localStorage.getItem("company_id")}/chatbots`,
    payload
  );

  if (res.status === 200) {
    return res.data;
  }
};

const deleteChatbot = async (chatbot_name) => {
  const res = await ManagerService().delete(
    `/companies/${localStorage.getItem(
      "company_id"
    )}/chatbots/${chatbot_name.toLowerCase()}`
  );
  if (res.status === 200) {
    return res.data;
  }
};

const uploadDatasources = async (chatbot_name, files) => {
  const formdata = new FormData();
  files.forEach((file, i) => {
    formdata.append(`files`, file, file.name);
  });

  const res = await ManagerService().post(
    `/companies/${localStorage.getItem(
      "company_id"
    )}/chatbots/${chatbot_name.toLowerCase()}/datasources`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  if (res.status === 200) {
    return res.data;
  }
};

const addTagsToDatasource = async (chatbot_name, datasource_id, payload) => {
  const res = await ManagerService().post(
    `/companies/${localStorage.getItem(
      "company_id"
    )}/chatbots/${chatbot_name.toLowerCase()}/datasources/${datasource_id}/tags`,
    payload
  );
};

const fetchChatbotConfig = async (chatbot_name) => {
  const res = await ManagerService().get(
    `/companies/${localStorage.getItem(
      "company_id"
    )}/chatbots/${chatbot_name.toLowerCase()}/configuration`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
      },
    }
  );
  if (res.status === 200) {
    return res.data;
  }
};

const fetchCompanyConstraints = async (company_id) => {
  const res = await ManagerService().get(
    `/api/v1/companies/${company_id}/constraints`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
      },
    }
  );
  if (res.status === 200) {
    return res.data;
  }
};

const setUserPermission = async (chatbot_name, payload) => {
  const res = await ManagerService().post(
    `/companies/${localStorage.getItem(
      "company_id"
    )}/chatbots/${chatbot_name.toLowerCase()}/permissions`,
    payload
  );
  if (res.status === 200) {
    return res.data;
  }
};

const getUsersPermission = async (chatbot_name) => {
  const res = await ManagerService().get(
    `/companies/${localStorage.getItem(
      "company_id"
    )}/chatbots/${chatbot_name.toLowerCase()}/permissions`
  );
  if (res.status === 200) {
    return res.data;
  }
};

const sendFeedback = async (chatbot_name, payload) => {
  const res = await ManagerService().post(
    `/companies/${localStorage.getItem(
      "company_id"
    )}/chatbots/${chatbot_name.toLowerCase()}/feedback`,
    payload
  );
  if (res.status === 200) {
    return res.data;
  }
};

const fetchCompanyConnectors = async () => {
  const res = await ManagerService().get(
    `/companies/${localStorage.getItem("company_id")}/connectors`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
      },
    }
  );
  if (res.status === 200) {
    return res.data;
  }
};

const fetchCompanyTools = async () => {
  const res = await ManagerService().get(
    `/companies/${localStorage.getItem("company_id")}/tools`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
      },
    }
  );
  if (res.status === 200) {
    return res.data;
  }
};

const deleteUserPermission = async (chatbot_name, user_sub) => {
  const res = await ManagerService().delete(
    `/companies/${localStorage.getItem(
      "company_id"
    )}/chatbots/${chatbot_name.toLowerCase()}/permissions/${user_sub}`
  );
  if (res.status === 200) {
    return res.data;
  }
};

export {
  fetchChatbots,
  fetchDatasources,
  fetchChatbotConfig,
  fetchChatbotsTotalSizeInMb,
  createChatbot,
  uploadDatasources,
  deleteChatbot,
  fetchCompanyConstraints,
  setUserPermission,
  sendFeedback,
  fetchCompanyConnectors,
  addTagsToDatasource,
  fetchCompanyTools,
  getUsersPermission,
  deleteUserPermission,
  fetchChatbotTools,
};
