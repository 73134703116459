import React, { useCallback, useEffect, useState } from "react";
import {
  fileValidation,
  getSizeDimension,
  delay,
} from "../../../services/utils";
import { extractFileExtensions } from "../../../services/utils";
import { useNotValidFilesState } from "../../../states/useStates";
import { MdDeleteOutline } from "react-icons/md";
import Input from "../../Forms/Input";
import { fetchColumnsNames } from "../../../services/api/PipelineService";
import { BsStars } from "react-icons/bs";
import { fetchColumnsDescription } from "../../../services/api/CompleteWithAIService";
import Spinner from "../../Spinner/Spinner";
import { fetchTaskStatus } from "../../../services/api/TaskService";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import {
  connectorErrorsState,
  isConnectorValidState,
} from "../../../states/atoms";

function ListUploadedFile({
  handleFileRemove,
  handleFileChange,
  setFiles,
  files,
  validFormat,
}) {
  const [notValidFiles, setNotValidFiles] = useNotValidFilesState();
  const [total, setTotal] = useState(null);
  const hiddenFileInput = React.useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  const [tableDescription, setTableDescription] = useState("");
  const [startAutoComplete, setStartAutoComplete] = useState(false);

  const [isConnectorValid, setIsConnectorValid] = useRecoilState(
    isConnectorValidState
  );

  const [connectorErrors, setConnectorErrors] =
    useRecoilState(connectorErrorsState);

  const addMetadata = (target, metadata) => {
    const updatedFiles = files.map((item) => {
      if (item.name === target) {
        item.tags = metadata;
      }
      return item;
    });

    setFiles(updatedFiles);
  };

  const handleAddDescription = (target, description) => {
    setTableDescription(description);
    const updatedFiles = files.map((item) => {
      if (item.name === target) {
        item.table_description = description;
      }
      return item;
    });
    setFiles(updatedFiles);
  };

  const handleUploadClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileUpload = (e) => {
    const arr = Array.from(e.target.files);
    const validFiles = fileValidation(validFormat, arr, "structured-text");

    handleFileChange(validFiles);
  };

  const handleRetrieveColumns = async () => {
    selectedFile.tags = {};
    const columns = await fetchColumnsNames(selectedFile);
    let tags = {};
    columns.forEach((item) => {
      tags[item] = "";
    });

    const updatedFiles = files.map((item) => {
      if (item.name === selectedFile.name) {
        item.tags = tags;
      }
      return item;
    });

    setFiles(updatedFiles);
  };

  const deleteColumnFromFile = (key) => {
    delete selectedFile.tags[key];
    const updatedFiles = files.map((item) => {
      if (item.name === selectedFile.name) {
        item.tags = selectedFile.tags;
      }
      return item;
    });
    setFiles(updatedFiles);
  };

  const getAsyncResponse = async (job_id) => {
    const res = await fetchTaskStatus(job_id);
    if (res && res.completed === true) {
      if (Object.keys(res.result.columns).length <= 0) {
        toast.error("Autocomplete failed.");
        setStartAutoComplete(false);
        return;
      }

      const updatedFiles = files.map((item) => {
        if (item.name === selectedFile.name) {
          item.table_description = res.result.table_description;
          item.tags = res.result.columns;
        }
        return item;
      });
      setFiles(updatedFiles);
      setStartAutoComplete(false);
      toast.success("Autocomplete finished.");
    } else {
      // Wait for a while and make the API call again
      await delay(5000); // You can adjust the delay as needed
      await getAsyncResponse(job_id); // Recursive call
    }
  };

  const handleAutoComplete = async () => {
    setStartAutoComplete(true);

    const res = await fetchColumnsDescription(selectedFile);
    console.log(res);
    if (res) {
      await getAsyncResponse(res.job_id);
    }
  };

  const validateConnector = useCallback(() => {
    let errors = [];
    let noDescription = false;
    let noTags = false;
    let tagInvalid = false;

    files.forEach((file) => {
      if (!file.table_description || file.table_description.length <= 0) {
        noDescription = true;
      }

      if (!file.tags) {
        noTags = true
      } else {
        Object.keys(file.tags).forEach((tag) => {
          if (file.tags[tag] === "") {
            tagInvalid = true;
          }
        });
      }
    });

    if (noDescription) {
      errors.push("The uploaded file/s must have a description.");
    }

    if (noTags) {
      errors.push("The uploaded files must have columns.");
    }

    if (tagInvalid) {
      errors.push("All the columns must have a description.")
    }

    console.log(errors);
    if (errors.length > 0) {
      setIsConnectorValid(false);
      setConnectorErrors(errors);
    } else {
      setIsConnectorValid(true);
    }
  }, [files]);

  useEffect(() => {
    let totalSize = 0;
    files.map((item) => {
      totalSize += item.size;
    });

    setTotal(getSizeDimension(totalSize));
    if (selectedFile === undefined) {
      setSelectedFile(files[0]);
    } else {
      setSelectedFile(selectedFile);
    }
  }, [files, selectedFile]);

  useEffect(() => {
    setTableDescription(selectedFile?.tableDescription || "");
  }, [selectedFile]);

  useEffect(() => {
    validateConnector();
  }, [files, validateConnector]);

  return (
    <div className="relative overflow-x-auto p-5 bg-white rounded flex gap-5  shadow">
      <div className="flex flex-col w-1/3 gap-2">
        {files &&
          files.map((item, index) => (
            <div
              key={index}
              className={`${
                selectedFile && selectedFile.name === item.name
                  ? "bg-primary text-white"
                  : ""
              } ${
                startAutoComplete ? "pointer-events-none opacity-30" : ""
              } p-2 border border-gray/20 rounded flex items-center justify-between cursor-pointer hover:bg-primary hover:text-white`}
              onClick={() => setSelectedFile(item)}
            >
              <div className="flex items-center gap-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/file_type/" +
                    extractFileExtensions(item.name, true) +
                    ".png"
                  }
                  alt="file extension icon"
                  className="h-6 lg:h-12"
                />
                <div className="flex flex-col lg:flex-row gap-x-2 min-w-0 gap-y-1">
                  <span className="text-xs font-bold truncate max-w-[12rem]">
                    {item.name}
                  </span>
                  <span className="text-xs">{getSizeDimension(item.size)}</span>
                </div>
              </div>
              <MdDeleteOutline
                className="text-xl cursor-pointer"
                onClick={() => handleFileRemove(item)}
              />
            </div>
          ))}
        <div
          className={`border border-dotted text-xs lg:text-sm border-gray/20 p-5 rounded text-gray/50 hover:border-gray hover:text-gray cursor-pointer ${
            startAutoComplete ? "pointer-events-none opacity-30" : ""
          }`}
          onClick={() => handleUploadClick()}
        >
          + Click to add new file
        </div>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={(e) => handleFileUpload(e)}
          style={{ display: "none" }}
          multiple
        />
      </div>
      <div className="border-l border-l-gray/10 px-5 w-2/3">
        <div className="flex  gap-y-2 justify-between">
          <h1 className="font-bold underline decoration-primary decoration-4 text-xl">
            Metadata
          </h1>
          <button
            className={`p-2 bg-primary rounded text-white ${
              selectedFile?.tags && Object.keys(selectedFile?.tags).length > 0
                ? "hidden"
                : ""
            }`}
            onClick={() => handleRetrieveColumns(selectedFile)}
          >
            Retrieve colums
          </button>
        </div>
        {/* metadata form and date */}
        <div className="mt-2">
          <div className="flex flex-col gap-3">
            <Input
              label={"Description"}
              placeholder={"This file is about..."}
              value={selectedFile?.table_description || tableDescription}
              onChange={(e) =>
                handleAddDescription(selectedFile.name, e.target.value)
              }
            />

            {selectedFile?.tags &&
            Object.keys(selectedFile?.tags).length > 0 ? (
              <div className="flex items-center justify-between mt-2">
                <span>Describe Columns</span>
                <div
                  className="flex items-center gap-1 text-xs border border-gray/20 p-2 shadow rounded hover:bg-primary hover:text-white cursor-pointer"
                  onClick={handleAutoComplete}
                >
                  {startAutoComplete ? (
                    <Spinner text={"Autocompleting..."} />
                  ) : (
                    <>
                      <BsStars />
                      Complete with AI
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="text-xs">
                Click on Retrieve Columns select the columns you want to analyze
                and add a useful description
              </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
              {selectedFile?.tags &&
                Object.keys(selectedFile?.tags).map((key, index) => {
                  return (
                    <div key={index}>
                      <Input
                        label={
                          <div className="flex items-center justify-between">
                            {key}{" "}
                            <MdDeleteOutline
                              className="text-xl cursor-pointer"
                              onClick={() => deleteColumnFromFile(key)}
                            />
                          </div>
                        }
                        placeholder={"This column is about..."}
                        value={selectedFile?.tags[key]}
                        onChange={(e) =>
                          addMetadata(selectedFile.name, {
                            ...selectedFile.tags,
                            [key]: e.target.value,
                          })
                        }
                      />
                    </div>
                  );
                })}
            </div>
          </div>

          {/* <div className="absolute bottom-0 left-5">
            <BaseButton value={"Save"} onClick={() => handleSaveMetadata()} />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ListUploadedFile;
