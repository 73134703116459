import React, { useState, useEffect } from "react";
import KnowledgeBaseSingleCards from "./KnowledgeBaseSingleCards";
import Omnisearch from "../../components/Omnisearch/Omnisearch";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { FaTrash } from "react-icons/fa";
import toast from "react-hot-toast";
import { IoIosCloseCircleOutline } from "react-icons/io";

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import {
  getUsersPermission,
  deleteUserPermission,
  setUserPermission,
} from "../../services/api/ManagerService";

import { fetchUsers } from "../../services/api/AdminService";
import KnowledgeBaseSingleRow from "./KnowledgeBaseSingleRow";

function KnowledgeBaseCards({ datastores }) {
  const [currentDatastores, setCurrentDatastores] = useState([]);
  const [selectedDatastore, setSelectedDatastore] = useState(null);
  const [chatbotUsers, setChatbotUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]); // all users in the company
  const [filteredAllUsers, setFilteredAllUsers] = useState([]); // all users in the company
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [addUsersToChatbot, setAddUsersToChatbot] = useState([]); // users to be added to chatbot

  const handleChatbotDeleted = (item) => {
    const filtered = currentDatastores.filter(
      (datastore) => datastore.id !== item.id
    );
    setCurrentDatastores(filtered);
  };

  const handleViewUsers = async (item) => {
    setShowAddUserModal(true);
    const res = await getUsersPermission(item.chatbot_name);
    setSelectedDatastore(item.chatbot_name);
    if (res) {
      setChatbotUsers(res);
    } else {
      console.log("error on fetch users permission");
    }
  };

  const handleCloseUsersView = () => {
    setSelectedDatastore(null);
    setChatbotUsers([]);
  };

  const handleDeleteUserPermission = (chatbot_name, sub) => {
    const res = deleteUserPermission(chatbot_name, sub).catch((err) => {
      console.log(err);
    });

    if (!res) {
      toast.error("error on delete user permission");
      return;
    } else {
      const filteredUsers = chatbotUsers.filter((u) => u.sub !== sub);
      setChatbotUsers(filteredUsers);
      toast.success("User deleted correctly.");
    }
  };

  const handleViewAllUsers = async () => {
    setShowAddUserModal(true);
    const res = await fetchUsers().catch((err) => {
      console.log(err);
    });

    if (res) {
      // keep only users that are not in chatbotUsers
      const filteredUsers = res.filter((u) => {
        return !chatbotUsers.some((u2) => u.sub === u2.sub);
      });
      setAllUsers(filteredUsers);
    }
  };

  const handleSearch = (target) => {
    if (target !== "") {
      const filtered = allUsers.filter((item) =>
        item.fullname.toLowerCase().includes(target.toLowerCase())
      );
      setFilteredAllUsers(filtered);
    } else {
      setFilteredAllUsers(allUsers);
    }
  };

  const handleAddUsersToChatbot = async () => {
    // add users to chatbot
    // console.log(addUsersToChatbot);
    let newPermissions = [];
    for (let index = 0; index < addUsersToChatbot.length; index++) {
      newPermissions.push({
        sub: addUsersToChatbot[index].sub,
        email: addUsersToChatbot[index].email,
        access: true,
      });
    }
    const res = await setUserPermission(selectedDatastore, {
      users: newPermissions ? newPermissions : [],
    }).catch((err) => console.log(err));

    if (res) {
      toast.success("Users added correctly.");
      // add users to chatbotUsers
      const newChatbotUsers = [...chatbotUsers, ...addUsersToChatbot];
      setChatbotUsers(newChatbotUsers);
    }
    setShowAddUserModal(false);
    setAddUsersToChatbot([]);
  };

  const toggleAddUserToChatbot = (sub) => {
    const user = allUsers.find((u) => u.sub === sub);
    const isAdded = addUsersToChatbot.some((u) => u.sub === sub);
    if (isAdded) {
      const filtered = addUsersToChatbot.filter((u) => u.sub !== sub);
      setAddUsersToChatbot(filtered);
    } else {
      setAddUsersToChatbot([...addUsersToChatbot, user]);
    }
  };

  const handleCloseAddUserModal = () => {
    setShowAddUserModal(false);
    setAddUsersToChatbot([]);
  };

  useEffect(() => {
    setFilteredAllUsers(allUsers);
  }, [allUsers]);

  useEffect(() => {
    if (chatbotUsers.length > 0) {
      handleViewAllUsers();
    }
  }, [chatbotUsers]);

  useEffect(() => {
    if (datastores) {
      setCurrentDatastores(datastores);
    }
  }, [datastores]);

  return (
    <>
      {showAddUserModal ? (
        <div className="h-screen fixed bg-white shadow right-0 w-1/2 top-0 p-8 z-50 overflow-scroll">
          <div className="flex justify-between">
            <span className="font-bold text-2xl">Who can use the chatbot?</span>
            <button
              className="text-xs bg-gray/10 px-3 py-2 rounded flex gap-1 items-center"
              onClick={handleCloseAddUserModal}
            >
              <IoIosCloseCircleOutline className="text-xs" /> Close
            </button>
          </div>
          <div className="mt-10">
            <div className="mt-5 mb-5">
              {chatbotUsers.length > 0 ? (
                <div className="mt-3">
                  <table className="w-full text-sm text-left text-gray">
                    <thead className="text-xs text-gray uppercase bg-base">
                      <tr>
                        <th scope="col" className="p-2">
                          Fullname
                        </th>
                        <th scope="col" className="p-2">
                          Builder
                        </th>
                        <th scope="col" className="p-2">
                          Admin
                        </th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {chatbotUsers.map((u, i) => {
                        return (
                          <tr
                            className="bg-white border-b-2 border-base border-opacity-50"
                            key={i}
                          >
                            <td className="p-2">{u.fullname}</td>
                            <td className="p-2" scope="row">
                              {u.is_builder ? (
                                <RiCheckboxCircleFill className="text-primary text-xl" />
                              ) : null}
                            </td>
                            <td className="p-2" scope="row">
                              {u.is_admin ? (
                                <RiCheckboxCircleFill className="text-primary text-xl" />
                              ) : null}
                            </td>
                            <td className="p-2">
                              {!u.is_admin && !u.is_builder ? (
                                <FaTrash
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleDeleteUserPermission(
                                      selectedDatastore,
                                      u.sub
                                    )
                                  }
                                />
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>

            <div className="mt-10">
              <h2 className="font-bold">Add New Users</h2>
              <div className="mt-2 mb-5 w-full">
                <Omnisearch onSearch={(t) => handleSearch(t)} />
              </div>

              {filteredAllUsers.map((u, i) => {
                return (
                  <div
                    className="flex justify-between items-center mb-3"
                    key={i}
                  >
                    <span>{u.fullname}</span>
                    <div className="flex gap-2">
                      <input
                        type="checkbox"
                        onClick={() => toggleAddUserToChatbot(u.sub)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex items-center justify-center w-5/6 mt-10">
              <button
                className="bg-green-500 w-3/4 p-2 rounded text-white"
                onClick={handleAddUsersToChatbot}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div className="gap-5 justify-center justify-items-center text-xs">
        <Table>
          <TableCaption>A list of your chatbots.</TableCaption>
          <TableHeader className="bg-primary">
            <TableRow>
              <TableHead className="text-white">Name</TableHead>
              <TableHead className="text-white">Status</TableHead>
              <TableHead className="text-white">Author</TableHead>
              <TableHead className="text-white"># Docs</TableHead>
              <TableHead className="text-white">Created at</TableHead>
              <TableHead className="text-right text-white">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {currentDatastores.map((item, index) => {
              return (
                <KnowledgeBaseSingleRow
                  key={index}
                  item={item}
                  onDelete={(item) => handleChatbotDeleted(item)}
                  onUserView={(item) => handleViewUsers(item)}
                  isSelected={selectedDatastore === item.chatbot_name}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>
    </>
  );
}

export default KnowledgeBaseCards;
