import React, { useEffect, useState, useContext } from "react";
import { fetchUsers } from "../../services/api/AdminService";
import UsersTable from "./UsersTable";
import PageLayout from "../PageLayout";
import { AccountContext } from "../../services/AccountService";
import PageUnauthorized from "../../pages/Unauthorized/Unauthorized";
import { Routes, Route, Link, Navigate } from "react-router-dom";

function Admin({ user }) {
  const [users, setUsers] = useState([]);

  // useEffect that use fetchUsers from AdminService to get all users and save into users state
  useEffect(() => {
    if (user.is_admin) {
      fetchUsers().then((res) => {
        setUsers(res);
      });
    }
  }, []);

  if (!user.is_admin) {
    return <PageUnauthorized />;
  }

  return (
    <PageLayout>
      <div className="pl-2 lg:pl-0 pr-4">
        <div className="lg:mt-10 mb-4 lg:mb-10">
          <h1 className="text-2xl font-bold">Admin Area</h1>
        </div>
        <UsersTable
          users={users}
          onUsersChange={(new_users) => setUsers(new_users)}
        />
      </div>
    </PageLayout>
  );
}

export default Admin;
