import { atom } from "recoil";

export const promptScoreState = atom({
  key: "promptScoreState", // unique ID (with respect to other atoms/selectors)
  default: "",
});

export const chatbotCountState = atom({
  key: "chatbotCountState", // unique ID (with respect to other atoms/selectors)
  default: 0,
});

export const chatbotSizeState = atom({
  key: "chatbotSizeState", // unique ID (with respect to other atoms/selectors)
  default: "0.00",
});

export const datastoresState = atom({
  key: "datastoresState", // unique ID (with respect to other atoms/selectors)
  default: [],
});

export const queryCountState = atom({
  key: "queryCountState", // unique ID (with respect to other atoms/selectors)
  default: process.env.REACT_APP_MAX_QUERY,
});

export const notValidFilesState = atom({
  key: "notValidFilesState", // unique ID (with respect to other atoms/selectors)
  default: [],
});

export const currentUserState = atom({
  key: "currentUserState",
  default: null,
});

export const currentCompanyState = atom({
  key: "currentCompanyState",
  default: null,
});

export const companyConstraintsState = atom({
  key: "companyConstraintsState",
  default: null,
});

export const currentChatbotConfigState = atom({
  key: "currentChatbotConfigState",
  default: {},
});

export const isConnectorValidState = atom({
  key: "isConnectorValid",
  default: false,
});

export const connectorErrorsState = atom({
  key: "connectorErrorsState",
  default: [],
});

export const favoriteChatbotState = atom({
  key: "favoriteChatbotState",
  default: null,
});

export const modalUploadFileState = atom({
  key: "modalUploadFileState",
  default: false,
});
