import { cn } from '@/lib/utils'
import React from 'react'

function DefaultProgressBar({percentage, styleClass}) {
    return (
        <div className="w-full bg-grayDark rounded-full h-2.5">
            <div className={cn("h-2.5 rounded-full", styleClass)} style={{ width: `${percentage}%` }} />
        </div>
    )
}

export default DefaultProgressBar