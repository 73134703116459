import React from "react";
import { twMerge } from "tailwind-merge"

function BaseButton({ value, styleClass, onClick, disabled }) {
  return (
    <div>
      <button
        className={twMerge(`${
          disabled ? "cursor-not-allowed" : ""
        } px-8 py-2 rounded-xl font-bold ${styleClass}`)}
        onClick={onClick}
        disabled={disabled}
      >
        {value}
      </button>
    </div>
  );
}

BaseButton.defaultProps = {
  styleClass: "bg-primary text-white",
};

export default BaseButton;
