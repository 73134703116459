import axios from "axios";

const AdminService = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_ENDPOINT + "/api/v1/admin",
    headers: {
      "Content-Type": "application/json",
      "X-XSS-Protection": "X-XSS-Protection: 1; mode=block",
      "X-Frame-Options": "SAMEORIGIN",
      "Strict-Transport-Security": "max-age=10000000",
      "Content-Security-Policy": "default-src 'self'; child-src 'none';",
      "Referrer-Policy": "no-referrer",
      "X-Content-Type-Options": "nosniff",
      Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
    },
  };

  let instance = axios.create(defaultOptions);

  return instance;
};

export default AdminService();

const fetchUsers = async () => {
  const res = await AdminService().get(`/users`);
  if (res.status === 200) {
    return res.data;
  }
};

const addNewUser = async (fullname, email, isBuilder, isAdmin) => {
  let role = [];
  if (isBuilder) {
    role.push("builder");
  }
  if (isAdmin) {
    role.push("admin");
  }
  const payload = {
    fullname: fullname,
    email: email,
    role: role,
    company_name: localStorage.getItem("company_name"),
  };

  try {
    const res = await AdminService().post("/users", payload);
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

const deleteUser = async (sub) => {
  const res = await AdminService().delete(`/users/${sub}/deactivate`);
  if (res) {
    return res;
  }
};

export { fetchUsers, addNewUser, deleteUser };
