import axios from "axios";

const AnalyticsService = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_ENDPOINT + "/api/v1/analytics",
    headers: {
      "Content-Type": "application/json",
      "X-XSS-Protection": "X-XSS-Protection: 1; mode=block",
      "X-Frame-Options": "SAMEORIGIN",
      "Strict-Transport-Security": "max-age=10000000",
      "Content-Security-Policy": "default-src 'self'; child-src 'none';",
      "Referrer-Policy": "no-referrer",
      "X-Content-Type-Options": "nosniff",
      Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
    },
  };

  let instance = axios.create(defaultOptions);

  return instance;
};

export default AnalyticsService();

const addAnalyticsQueryUsage = async (data) => {
  return await AnalyticsService().post("/query-usage", data);
};

export { addAnalyticsQueryUsage };
