import { useState, createContext } from "react";
import AuthService from "./api/AuthService";
import UsersService from "./api/UsersService";

import {
  useCurrentUserState,
  useCurrentCompanyState,
  useCompanyConstraintsState,
} from "../states/useStates";
import ManagerService from "./api/ManagerService";

const AccountContext = createContext();

function AccountService({ children }) {
  const [currentUser, setCurrentUser] = useCurrentUserState();
  const [currentCompany, setCurrentCompany] = useCurrentCompanyState();
  const [companyConstraints, setCompanyConstraints] =
    useCompanyConstraintsState();
  const [sessionToken, setSessionToken] = useState(false);
  const [userPermission, setUserPermission] = useState(null);
  const [user, setUser] = useState(null);

  const login = async (email, password) => {
    return await new Promise((resolve, reject) => {
      AuthService.post("/login", {
        email: email,
        password: password,
      })
        .then((res) => {
          handleSessionToken(res.data.access_token);
          localStorage.setItem("company_id", res.data.company_id);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const whoAmI = async () => {
    return await new Promise((resolve, reject) => {
      UsersService.get("/me", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
        },
      })
        .then(async (res) => {
          setCurrentUser(res.data);
          handleUserIdentity(res.data);
          await whichCompany(res.data.company_id);
          await whichCompanyConstraints(res.data.company_id);
          localStorage.setItem("company_id", res.data.company_id);
          localStorage.setItem("sub", res.data.sub);
          localStorage.setItem("is_admin", res.data.is_admin);
          localStorage.setItem("is_builder", res.data.is_builder);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const whichCompany = async (company_id) => {
    return await new Promise((resolve, reject) => {
      ManagerService.get(`/companies/${company_id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
        },
      })
        .then((res) => {
          setCurrentCompany(res.data);
          localStorage.setItem("company_name", res.data.company_name);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const whichCompanyConstraints = async (company_id) => {
    return await new Promise((resolve, reject) => {
      ManagerService.get(`/companies/${company_id}/constraints`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
        },
      })
        .then((res) => {
          setCompanyConstraints(res.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const refreshToken = async (authToken) => {
    return await new Promise((resolve, reject) => {
      AuthService.post("token/refresh", {
        access_token: authToken,
      })
        .then((res) => {
          handleSessionToken(res.data.access_token);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const logout = () => {
    setSessionToken(false);
    setUserPermission("");
    localStorage.removeItem("localAuthorization");
  };

  const handleSessionToken = (sessionToken) => {
    setSessionToken(sessionToken);
    localStorage.setItem("localAuthorization", sessionToken);
  };

  const handleUserIdentity = (userIdentity) => {
    setUserPermission(
      userIdentity.is_superadmin
        ? "superadmin"
        : userIdentity.is_admin
        ? "admin"
        : userIdentity.is_builder
        ? "builder"
        : "user"
    );
  };

  return (
    <AccountContext.Provider
      value={{
        sessionToken,
        userPermission,
        login,
        whoAmI,
        whichCompany,
        refreshToken,
        logout,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export { AccountService, AccountContext };
