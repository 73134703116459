import React, { useEffect, useState } from "react";
import Logo from "./img/Logo.png";
import { useLocation } from "react-router-dom";
import BaseButton from "../Buttons/BaseButton";
import User from "./User";
import { Link } from "react-router-dom";
// import AuthService from "../../services/api/AuthService";
import { sidebarMenu } from "../../services/SidebarService";
import DefaultProgressBar from "../ProgressBar/DefaultProgressBar";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import {
  fetchChatbots,
  fetchChatbotsTotalSizeInMb,
} from "../../services/api/ManagerService";
import { fetchQueryUsage } from "../../services/api/UsersService";
import {
  useChatbotCountState,
  useChatbotSizeState,
  useQueryCountState,
} from "../../states/useStates";

import { useRecoilValue } from "recoil";
import { currentUserState, companyConstraintsState } from "../../states/atoms";
import { useWindowSize } from "@uidotdev/usehooks";
import UserWithStats from "./UserWithStats";
import { IoChatbubbles } from "react-icons/io5";

function Sidebar({ setOpenClose, isOpen }) {
  const location = useLocation();
  const [filteredSidebarMenu, setFilteredSidebarMenu] = useState(sidebarMenu);

  const [totalSize, setTotalSize] = useChatbotSizeState();
  const [totalSizePercentage, setTotatSizePercentage] = useState();

  const [chatbotCount, setChatbotCount] = useChatbotCountState();
  const [queryCount, setQueryCount] = useQueryCountState();
  const user = useRecoilValue(currentUserState);
  const companyConstraints = useRecoilValue(companyConstraintsState);

  const [isExpandible, setIsExpandible] = useState();
  const [isTall, setIsTall] = useState(true);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width && size.width < 1100) {
      setIsExpandible(false);
      setOpenClose(false);
    } else {
      setIsExpandible(true);
    }

    if (size.height < 650) {
      setIsTall(false);
    } else {
      setIsTall(true);
    }
  }, [isExpandible, setOpenClose, size]);

  const calculatePercentageTotSize = () => {
    // const MAX_SIZE = parseInt(
    //   process.env.REACT_APP_MAX_MB_SIZE.replace("MB", "")
    // );
    return (totalSize * 100) / companyConstraints.max_datasource_size_mb;
  };

  useEffect(() => {
    // if (process.env.REACT_APP_ENVIRONMENT === "sandbox") {
    //   setFilteredSidebarMenu(
    //     sidebarMenu.filter((item) => item.title !== "Chat")
    //   );
    // }

    if (!user.is_admin) {
      if (user.is_builder) {
        setFilteredSidebarMenu(
          sidebarMenu.filter((item) => item.title !== "Admin Area")
        );
      } else {
        setFilteredSidebarMenu([
          {
            title: "Chat",
            icon: <IoChatbubbles className="text-2xl" />,
            to: "/chat",
          },
        ]);
      }
    }

    fetchChatbots()
      .then((res) => setChatbotCount(res.length))
      .catch((err) => console.log(err));

    fetchChatbotsTotalSizeInMb()
      .then((res) => setTotalSize(res.total_size_mb))
      .catch((err) => console.log(err));
    setTotatSizePercentage(calculatePercentageTotSize());

    fetchQueryUsage()
      .then((res) => setQueryCount(res.query_usage))
      .catch((err) => console.log(err));
  }, []);

  // if (!user.is_admin && !user.is_builder) {
  //   return (
  //     <div className="flex items-center gap-8 justify-center py-4">
  //       <Link to="/">
  //         <img
  //           src={Logo}
  //           alt="chatto logo"
  //           className={`transition-all h-14`}
  //         />
  //       </Link>
  //       <div className="text-center flex justify-center items-center">
  //         <span className="text-xs font-bold">DAILY QUERIES:</span>
  //         <span className="ml-4">
  //           {companyConstraints.max_daily_query - queryCount}
  //         </span>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div
      className={`bg-white h-screen shadow relative ${isOpen ? "" : "w-24"}`}
    >
      {isExpandible && (
        <div
          className="absolute -right-5 top-5 bg-white rounded-full shadow p-2 cursor-pointer"
          onClick={() => setOpenClose(!isOpen)}
        >
          <MdKeyboardDoubleArrowLeft
            className={`text-xl text-primary transition-all ${
              isOpen ? "" : "rotate-180"
            }`}
          />
        </div>
      )}
      <div>
        <div className="flex justify-center py-8 flex-col items-center gap-5">
          <Link to="/">
            <img
              src={Logo}
              alt="chatto logo"
              className={`transition-all ${isOpen ? "h-24" : "h-12"}`}
            />
          </Link>
          <span className="font-bold text-primary uppercase">
            {process.env.REACT_APP_ENVIRONMENT === "sandbox"
              ? process.env.REACT_APP_ENVIRONMENT
              : ""}
          </span>
        </div>

        {isOpen ? (
          <div className="text-primary px-3">
            {(user.is_admin || user.is_builder) && (
              <div className="mb-5">
                <Link to="/builder/chatbots/new">
                  <BaseButton
                    value="+ Create new Chatbot"
                    styleClass={"bg-primary text-white w-full "}
                  />
                </Link>
              </div>
            )}
            <ul>
              {filteredSidebarMenu.map((data, index) => {
                return (
                  <li
                    className={`py-2 mb-3 cursor-pointer border-primary hover:border-l-4 hover:px-2 
                    ${
                      location.pathname === `${data.to}`
                        ? "border-l-4 px-2"
                        : ""
                    }`}
                    key={index}
                  >
                    <Link
                      className="flex items-center gap-x-3 text-xs"
                      to={data.to}
                    >
                      {data.icon}
                      {data.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
            {isTall ? (
              <div className="absolute bottom-5 w-5/6">
                <div className="border border-primary p-4 rounded border-dotted mb-8">
                  <div className="flex flex-col gap-4">
                    {(user.is_builder || user.is_admin) && (
                      <>
                        <div>
                          <h5 className="text-xs font-bold mb-2">USAGE SIZE</h5>
                          <div className="w-full flex justify-between text-xs mb-1">
                            <span>{Math.round(totalSize)}MB</span>
                            <span>
                              {Math.round(
                                companyConstraints.max_datasource_size_mb
                              )}
                              MB
                            </span>
                          </div>
                          <div className="w-full">
                            <DefaultProgressBar
                              styleClass={`${
                                totalSizePercentage === 100
                                  ? "bg-red-500"
                                  : "bg-primary"
                              }`}
                              percentage={totalSizePercentage}
                            />
                          </div>
                        </div>
                        <div className="flex items-center justify-between">
                          <h5 className="text-xs font-bold">CHATBOTS:</h5>
                          <span>
                            {chatbotCount} / {companyConstraints.max_chatbots}
                          </span>
                        </div>
                      </>
                    )}

                    <div className="flex items-center justify-between">
                      <span className="text-xs font-bold">DAILY QUERIES:</span>
                      <span className="ml-4">
                        {companyConstraints.max_daily_query - queryCount}
                      </span>
                    </div>
                  </div>
                </div>
                {user && (
                  <User
                    fullname={user.fullname}
                    company={localStorage.getItem("company_name")}
                  />
                )}
              </div>
            ) : (
              <div className="absolute bottom-5 w-5/6">
                {user && (
                  <UserWithStats
                    fullname={user.fullname}
                    minimize={false}
                    totalSize={totalSize}
                    companyConstraints={companyConstraints}
                    totalSizePercentage={totalSizePercentage}
                    chatbotCount={chatbotCount}
                    queryCount={queryCount}
                    openSidebar={isOpen}
                    isBaseUser={!(user.is_admin || user.is_builder)}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <div>
            {(user.is_admin || user.is_builder) && (
              <div className="mb-20 w-full flex justify-center">
                <Link to="/builder/chatbots/new">
                  <button className="border border-primary hover:bg-primary hover:text-white w-12 h-12 rounded text-2xl text-primary">
                    +
                  </button>
                </Link>
              </div>
            )}
            <ul className="flex flex-col items-center w-full">
              {filteredSidebarMenu.map((data, index) => {
                return (
                  <li
                    className={`py-2 mb-3 cursor-pointer ${
                      location.pathname === `${data.to}`
                        ? "px-2 bg-primary rounded"
                        : "hover:border-l-4 hover:border-primary hover:px-2"
                    }`}
                    key={index}
                  >
                    <Link
                      className={`flex items-center gap-x-3 text-xs ${
                        location.pathname === `${data.to}`
                          ? "text-white"
                          : "text-primary"
                      }`}
                      to={data.to}
                    >
                      {data.icon}
                    </Link>
                  </li>
                );
              })}
            </ul>

            <div className="absolute bottom-5 w-full flex justify-center">
              {user && (
                <UserWithStats
                  fullname={user.fullname}
                  minimize={true}
                  totalSize={totalSize}
                  companyConstraints={companyConstraints}
                  totalSizePercentage={totalSizePercentage}
                  chatbotCount={chatbotCount}
                  queryCount={queryCount}
                />
              )}
            </div>
          </div>
        )}
        {/* end - sidebar open */}
      </div>
    </div>
  );
}

export default Sidebar;
