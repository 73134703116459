import React, { useState, useEffect } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import BaseButton from "../../components/Buttons/BaseButton";
import Input from "../../components/Forms/Input";
import Checkbox from "../../components/Forms/Checkbox";
import { BiSave } from "react-icons/bi";
import { addNewUser, deleteUser } from "../../services/api/AdminService";
import toast from "react-hot-toast";

function UsersTable({ users, onUsersChange }) {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [isBuilder, setIsBuilder] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showNewUserForm, setShowNewUserForm] = useState(false);

  const resetForm = () => {
    setEmail("");
    setFullname("");
    setIsBuilder(false);
    setIsAdmin(false);
  };

  const handleAddNewUser = async () => {
    const res = await addNewUser(fullname, email, isBuilder, isAdmin);
    console.log(fullname, email, isBuilder, isAdmin);
    if (res.status === 200) {
      setShowNewUserForm(false);
      onUsersChange([...users, res.data]);
      toast.success("User created.");
      resetForm();
    } else if (res.status === 409) {
      toast.error("User already exists.");
      resetForm();
    } else {
      toast.error("Something went wrong.");
    }
  };

  const handleDeleteUser = async (sub) => {
    const res = await deleteUser(sub);
    const new_users = users.filter((user) => user.sub !== sub);
    onUsersChange(new_users);
    toast.success("User deleted.");
  };

  return (
    <div>
      <div className="flex lg:justify-end mb-10">
        <BaseButton
          value={"Add new user"}
          onClick={() => setShowNewUserForm(true)}
        />
      </div>
      <div class="relative overflow-x-auto">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs uppercase bg-primary text-white">
            <tr>
              <th scope="col" class="px-6 py-3">
                Fullname
              </th>
              <th scope="col" class="px-6 py-3">
                Email
              </th>
              <th scope="col" class="px-6 py-3">
                Builder
              </th>
              <th scope="col" class="px-6 py-3">
                Admin
              </th>
              <th scope="col" class="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => {
              return (
                <tr class="border-b" key={index}>
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray whitespace-nowrap"
                  >
                    {user.fullname}
                  </th>
                  <td class="px-6 py-4">{user.email}</td>
                  <td class="px-6 py-4">
                    {user.is_builder ? (
                      <IoIosCheckmarkCircle className="text-2xl text-primary" />
                    ) : (
                      ""
                    )}
                  </td>
                  <td class="px-6 py-4">
                    {" "}
                    {user.is_admin ? (
                      <IoIosCheckmarkCircle className="text-2xl text-primary" />
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="flex gap-2 justify-center  h-full mt-2">
                    <button className="p-2 bg-red-500 text-white rounded">
                      <MdDeleteOutline
                        className="text-xl"
                        onClick={() => handleDeleteUser(user.sub)}
                      />
                    </button>
                    {/* <button className="p-2 bg-gray-500 text-white rounded">
                      <MdEdit
                        className="text-xl"
                        onClick={() => handleDeleteUser(user.sub)}
                      />
                    </button> */}
                  </td>
                </tr>
              );
            })}
            {showNewUserForm ? (
              <tr>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray whitespace-nowrap"
                >
                  <Input
                    value={fullname}
                    type={"text"}
                    onChange={(e) => setFullname(e.target.value)}
                  />
                </th>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray whitespace-nowrap"
                >
                  <Input
                    value={email}
                    type={"email"}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </th>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray whitespace-nowrap"
                >
                  <Checkbox onClick={() => setIsBuilder(!isBuilder)} />
                </th>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray whitespace-nowrap"
                >
                  <Checkbox onClick={() => setIsAdmin(!isAdmin)} />
                </th>
                <th>
                  <div className="flex items-center">
                    <button className="bg-primary text-white p-2 rounded">
                      <BiSave className="text-2xl" onClick={handleAddNewUser} />
                    </button>
                    <button
                      className="p-2 rounded ml-3"
                      onClick={() => setShowNewUserForm(false)}
                    >
                      <MdDeleteOutline className="text-2xl" />
                    </button>
                  </div>
                </th>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UsersTable;
