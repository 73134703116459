import React, { useState, useEffect } from "react";
import { RiSendPlaneFill } from "react-icons/ri";
import { AiOutlineClear, AiOutlineSend } from "react-icons/ai";
import { BsGear } from "react-icons/bs";
import Slider from "../Forms/Slider";
import SelectBase from "../Forms/SelectBase";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useCurrentChatbotConfigState } from "../../states/useStates";

function ChatInputPlayground({
  onClick,
  onChange,
  onClear,
  query,
  currentConfig,
  onAdvancedSettingsChange,
}) {
  const [temperature, setTemperature] = useState("0.7");
  const [maxTokens, setMaxTokens] = useState("2048");
  const [chainType, setChainType] = useState({
    value: "stuff",
    label: "stuff",
  });
  const [showAdvancedSetting, setShowAdvancedSettings] = useState(false);

  const handleClick = () => {
    onClick(query);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      onClick(query);
    }
  };

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const handleClear = (e) => {
    onClear(e.target.value);
  };

  useEffect(() => {
    if (Object.keys(currentConfig).length > 0) {
      setTemperature(currentConfig.temperature);
      setChainType({
        value: currentConfig.chain_type.replace("-", "_"),
        label: currentConfig.chain_type,
      });
      setMaxTokens(currentConfig.max_tokens);
    }
  }, [currentConfig]);

  return (
    <>
      {showAdvancedSetting ? (
        <div className="w-full flex justify-end">
          <div className="bg-white p-3 rounded-xl w-1/2 flex flex-col gap-5 shadow">
            <h3 className="font-bold text-primary">Advanced Settings</h3>
            <Slider
              label={"Temperature"}
              min={0}
              max={1}
              step={0.1}
              start={temperature}
              onChange={(t) =>
                onAdvancedSettingsChange({ ...currentConfig, temperature: t })
              }
            />
            <Slider
              label={"Max Tokens"}
              min={1024}
              max={4096}
              step={1}
              start={maxTokens}
              onChange={(mt) =>
                onAdvancedSettingsChange({
                  ...currentConfig,
                  max_tokens: mt,
                })
              }
            />
            {/* <SelectBase
              value={chainType}
              onChange={(ct) =>
                onAdvancedSettingsChange({
                  ...currentConfig,
                  chain_type: ct.value,
                })
              }
              label={"Chain Type"}
              options={[
                { value: "stuff", label: "stuff" },
                { value: "refine", label: "refine" },
                { value: "map_reduce", label: "map-reduce" },
                { value: "map_rerank", label: "map-rerank" },
              ]}
            /> */}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="flex w-full align-center content-center items-center justify-center gap-x-2 bg-white p-4 rounded-xl">
        {/* <Input className='' label="Ask a question..." onChange={handleChange} value={query}/> */}
        <input
          onKeyDown={(e) => handleEnterKeyPress(e)}
          onChange={handleChange}
          value={query}
          type="text"
          placeholder="Ask me a question..."
          className="w-full h-fit p-2 outline-none border border-gray/20 rounded-xl"
        />
        <AiOutlineSend
          data-tooltip-id="send-tooltip"
          data-tooltip-content="Send"
          className="h-10 w-10 cursor-pointer rounded-xl text-white bg-primary p-2"
          onClick={handleClick}
        />
        <AiOutlineClear
          data-tooltip-id="clear-tooltip"
          data-tooltip-content="Clear Chat and History"
          className="h-10 w-10 cursor-pointer rounded-xl hover:text-white border border-primary hover:bg-primary bg-white p-2"
          onClick={handleClear}
        />
        <BsGear
          data-tooltip-id="settings-tooltip"
          data-tooltip-content="Open Adavanced Settings"
          className={`h-10 w-10 cursor-pointer rounded-xl hover:text-white border border-primary hover:bg-primary ${
            showAdvancedSetting ? "bg-primary text-white" : "bg-white"
          } p-2`}
          onClick={() => setShowAdvancedSettings(!showAdvancedSetting)}
        />
      </div>
      <ReactTooltip id="send-tooltip" />
      <ReactTooltip id="clear-tooltip" />
      <ReactTooltip id="settings-tooltip" />
    </>
  );
}

export default ChatInputPlayground;
