import React, { useState } from "react";
import { BiHappyHeartEyes, BiSad } from "react-icons/bi";
import BaseButton from "../../components/Buttons/BaseButton";
import Markdown from "../../components/Markdown/Markdown";
import { SyncLoader } from "react-spinners";

function BotMessage({
  id,
  message,
  prompts,
  isLoading,
  onPositiveFeedback,
  onNegativeFeedback,
}) {
  const [expectedAnswer, setExpectedAnswer] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isNegativeFeedback, setIsNegativeFeedback] = useState(false);
  const [isPositiveFeedback, setIsPositiveFeedback] = useState(false);

  const handleSendPositiveFeedback = () => {
    setIsPositiveFeedback(true);
    onPositiveFeedback({
      message_id: id,
      answer: message,
      is_correct: true,
      source: "playground",
    });
  };

  const handleNegativeFeedback = () => {
    setIsNegativeFeedback(true);
    onNegativeFeedback({
      message_id: id,
      answer: message,
      is_correct: false,
      expected_answer: expectedAnswer,
      source: "playground",
    });
  };

  return (
    <>
      {showModal ? (
        <div className="absolute bg-white w-full h-fit top-10 rounded-xl shadow p-10 z-50">
          <div className="flex justify-end">
            <span
              className="cursor-pointer"
              onClick={() => setShowModal(false)}
            >
              X
            </span>
          </div>
          <h2 className="text-xl font-bold mb-5">Expected Answer</h2>
          <textarea
            className="w-full border border-gray/30 h-fit outline-none p-5"
            value={expectedAnswer}
            onChange={(e) => setExpectedAnswer(e.target.value)}
          ></textarea>
          <div className="flex justify-end mt-5">
            <BaseButton
              value={"Send Feedback"}
              onClick={() => handleNegativeFeedback()}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="border-b p-5 ">
        <div className="flex gap-x-5 items-start">
          <div className="bg-primary text-white font-bold px-4 py-1 rounded-xl">
            BOT
          </div>
          <div className="w-full">
            <div
              className={`text-xs flex flex-col ${
                prompts.length > 0 ? "" : ""
              }`}
            >
              {/* <span className={`${prompts.length > 0 ? "font-bold" : "hidden"}`}>
              Prompt
            </span> */}
              {/* {prompts.map((prompt,index) => {
                    return <span className='italic' key={index}>{prompt.text}</span>
                    })} */}
            </div>
            {isLoading ? (
              <SyncLoader color="#36d7b7" size="8" className="mt-8" />
            ) : (
              <p className="prose max-w-none prose-table:p-2 prose-thead:border prose-thead:bg-primary prose-tr:border prose-th:border prose-td:border">
                <Markdown text={message} />
              </p>
            )}
          </div>
        </div>
        <div className="flex justify-end mb-2 gap-x-2">
          <BiHappyHeartEyes
            className={`text-xl hover:text-primary cursor-pointer ${
              isPositiveFeedback ? "text-primary" : ""
            }`}
            onClick={handleSendPositiveFeedback}
          />
          <BiSad
            className={`text-xl hover:text-primary cursor-pointer ${
              isNegativeFeedback ? "text-primary" : ""
            }`}
            onClick={() => setShowModal(true)}
          />
        </div>
      </div>
    </>
  );
}

export default BotMessage;
