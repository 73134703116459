import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog-without-close";
import { Progress } from "@/components/ui/progress";
import { useNavigate } from "react-router-dom";

const getProgressbarColor = (color) => {
  if (color === "red") return "bg-red-500";
  if (color === "green") return "bg-green-500";
  if (color === "light-blue") return "bg-primary";
};
const ModalBuilder = ({
  progressValue,
  text,
  progressColor,
  chatbot,
  isValid,
  onOpen,
}) => {
  const navigate = useNavigate();
  return (
    <Dialog on>
      <DialogTrigger asChild>
        <Button
          onClick={() => onOpen()}
          variant="default"
          disabled={!isValid}
          className="font-semibold text-lg rounded-lg"
        >
          Create Chatbot
        </Button>
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-[425px]"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-3xl">
            {progressValue === 100 ? "Congrats!" : "Creatating the chatbot..."}
          </DialogTitle>
          <DialogDescription>
            {progressValue === 100
              ? " Your new chatbot is under construction."
              : "Please do not refresh the page."}
          </DialogDescription>
        </DialogHeader>
        {progressValue === 100 ? (
          <div className="mb-5 flex items-center">
            <img
              className="h-24 mr-5"
              src={process.env.PUBLIC_URL + "/images/construction.png"}
              alt="chatbot animation saying hi!"
            />
            <div className="mt-5">
              <div className="flex flex-col gap-2 justify-center items-center">
                <p className="text- mb-2">
                  You will receive an email when your chatbot is ready to be
                  used.
                </p>
                <Button
                  onClick={() =>
                    navigate("/", {
                      state: { chatbot: `${chatbot}` },
                    })
                  }
                >
                  GO TO CHATBOTS
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <p className="text-lg font-light">{text}</p>
            <Progress
              value={progressValue}
              className="bg-slate-200 w-full"
              progressColor={getProgressbarColor(progressColor)}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalBuilder;
