import React from "react";

function NotAvailable({ styleClass, message }) {
  return (
    <div className={`${styleClass} px-1 flex`}>
      <span className="text-white font-bold bg-primary px-1 rounded text-xs">
        {message}
      </span>
    </div>
  );
}

NotAvailable.defaultProps = {
  message: "Not Available",
};

export default NotAvailable;
