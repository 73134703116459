import React, { useState } from "react";

function ChatbotSlider({ label, min, max, step, start, onChange }) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="flex justify-between items-center">
        <label className="font-bold uppercase text-xs">{label}</label>
        <p className="font-bold">{start}</p>
      </div>
      {/* <Slider className='text-primary' size="sm" min={min} max={max} step={step} defaultValue={value} onChange={(e) => setValue(e.target.value)} /> */}
      <input
        onChange={handleChange}
        type="range"
        min={min}
        max={max}
        step={step}
        value={start}
        className="range accent-primary mt-3 cursor-grabbing h-1 range-sm"
      ></input>
    </div>
  );
}

export default ChatbotSlider;
