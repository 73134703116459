import toast from "react-hot-toast";

const validFormats = {
  "free-text": [
    ".doc",
    ".docx",
    ".enex",
    ".epub",
    ".html",
    ".xhtml",
    ".md",
    ".odt",
    ".pdf",
    ".ppt",
    ".pptx",
    ".txt",
  ],
  "structured-text": [".csv", ".xls", ".xlsx"],
};

const getSizeDimension = (size) => {
  var fSExt = new Array("Bytes", "KB", "MB", "GB"),
    i = 0;
  while (size > 900) {
    size /= 1024;
    i++;
  }
  var exactSize = Math.round(size * 100) / 100 + " " + fSExt[i];
  return exactSize;
};

const convertSizeToGB = (size, withDimension) => {
  let converted;
  if (size.includes("Bytes")) {
    converted = size.replace(" Bytes", "") / Math.pow(1024, 3);
  } else if (size.includes("KB")) {
    converted = size.replace("KB", "") / Math.pow(1024, 2);
  } else if (size.includes("MB")) {
    converted = size.replace("MB", "") / Math.pow(1024, 1);
  } else {
    converted = size.replace("GB", "");
  }

  if (withDimension) {
    return `${parseFloat(converted).toFixed(2)}GB`;
  } else {
    return parseFloat(converted).toFixed(2);
  }
};

const convertSizeToMB = (size, withDimension) => {
  let converted;
  if (size.includes("Bytes")) {
    converted = size.replace(" Bytes", "") / Math.pow(1024, 3);
  } else if (size.includes("KB")) {
    converted = size.replace("KB", "") / Math.pow(1024, 2);
  } else {
    converted = size.replace("MB", "");
  }

  if (withDimension) {
    return `${parseFloat(converted).toFixed(2)}MB`;
  } else {
    return parseFloat(converted).toFixed(2);
  }
};

const calculateLeftSizePercentage = (first, second) => {
  const firstGB = convertSizeToMB(first);
  const secondGB = convertSizeToMB(second);

  const value = (firstGB * 100) / secondGB;
  if (value < 1) {
    return 0;
  } else if (value > 100) {
    return 100;
  } else {
    return value;
  }
};

const parseMentionInput = (message) => {
  if ("@[chatbot:".includes(message)) {
    let regex = /\(chatbot:([^\)]+)\)/;
    let cleanRegex = /@\[[^\]]+\]\(chatbot:[^\)]+\)\s?/g;
    let match = message.match(regex);
    try {
      return {
        chatbot: match[1],
        message: message.replace(cleanRegex, "").trim(),
      };
    } catch {
      return { chatbot: "", message: message.replace(cleanRegex, "").trim() };
    }
  } else {
    return { chatbot: "", message: message };
  }
};

const parseToolInput = (message) => {
  let regex = /\(tool:([^\)]+)\)/;
  let cleanRegex = /#\[[^\]]+\]\(tool:[^\)]+\)\s?/g;
  let match = message.match(regex);
  if (!match) {
    return { tool: "", message: message.replace(cleanRegex, "").trim() };
  }
  return { tool: match[1], message: message.replace(cleanRegex, "").trim() };
};

const parseMessage = (message) => {
  if ("@[chatbot:".includes(message)) {
    let cleanRegex1 = /@\[[^\]]+\]\(chatbot:[^\)]+\)\s?/g;
    let cleanRegex2 = /#\[[^\]]+\]\(tool:[^\)]+\)\s?/g;

    return message.replace(cleanRegex1, "").replace(cleanRegex2, "").trim();
  } else {
    return message;
  }
};

const sliceLongFilename = (filename) => {
  if (filename.length > 20) {
    return `${filename.slice(0, 10)}...${extractFileExtensions(filename)}`;
  } else {
    return filename;
  }
};

const sliceFilename = (filename, n) => {
  if (filename.length > 20) {
    return `${filename.slice(0, n)}...${extractFileExtensions(filename)}`;
  } else {
    return filename;
  }
};

const sliceLongText = (text, maxLength, sliceNum) => {
  if (text.length > maxLength) {
    return `${text.slice(0, sliceNum)}...`;
  } else {
    return text;
  }
};

function extractFileExtensions(text, removeDot) {
  const regex = /\.\w+/g; // Regular expression pattern
  const extensions = text.match(regex); // Use the match method to find all matches
  if (removeDot) {
    return extensions[0].split(".")[1];
  }
  return extensions[0]; // Return an array of extensions, or an empty array if none found
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const notifyInvalidFiles = (files) => {
  toast(
    <div className="flex flex-col gap-2 max-w-[20rem]">
      <div className="flex gap-2">
        <div className="text-lg">⚠️</div>
        <h2>The following files are not valid for the selected connector:</h2>
      </div>
      <div className="flex flex-col gap-2">
        {files.map((file) => (
          <p className="truncate">📄 {file.name}</p>
        ))}
      </div>
    </div>
  );
};

const fileValidation = (validFormat, newfiles, connectorType) => {
  const validFiles = [];
  const invalidFiles = [];
  let files = Array.from(newfiles);

  files.forEach((f) => {
    const ext = extractFileExtensions(f.name);
    if (validFormat.indexOf(ext) === -1) {
      invalidFiles.push(f);
    } else {
      if (connectorType === "free-text") {
        if (freeTextFileValidation(f)) {
          validFiles.push(f);
        }
      } else if (connectorType === "structured-text") {
        if (structuredTextFileValidation(f)) {
          validFiles.push(f);
        }
      }
    }
  });

  if (invalidFiles.length > 0) {
    notifyInvalidFiles(invalidFiles);
  }

  return validFiles;
};

const freeTextFileValidation = (file) => {
  if (file.type === "application/pdf" && file.size >= 100 * 1024 * 1024) {
    toast.error("The size limit for pdf files is 100mb.");
    return false;
  } else return true;
};

const structuredTextFileValidation = (file) => {
  // custom validation besides file extension (file size, etc...)
  return true;
};

const getValidFormats = (connectorType) => {
  return validFormats[connectorType];
};

export {
  getSizeDimension,
  calculateLeftSizePercentage,
  convertSizeToGB,
  parseMentionInput,
  sliceFilename,
  sliceLongFilename,
  convertSizeToMB,
  sliceLongText,
  extractFileExtensions,
  delay,
  parseToolInput,
  parseMessage,
  fileValidation,
  freeTextFileValidation,
  getValidFormats,
};
