import React, { useState, useEffect } from "react";
import Select from "react-select";

function SelectWithIcons({
  label,
  options,
  onChange,
  field,
  value,
  bgColor,
  styleClass,
  available,
  menuPlacement,
  placeholder,
}) {
  const handleChange = (e) => {
    if (e.provider !== "google") {
      onChange(e);
    }
  };

  return (
    <div className={`flex flex-col gap-y-2 ${styleClass}`}>
      <label className="font-bold uppercase text-xs">{label}</label>
      <Select
        menuPlacement={menuPlacement}
        onChange={(e) => handleChange(e)}
        value={value}
        placeholder={placeholder}
        options={options}
        isOptionDisabled={(option) => !option.available}
        className="react-select-container"
        classNamePrefix="react-select"
        getOptionLabel={(e) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              alignContent: "space-between",
            }}
          >
            {e.icon}
            <span style={{ marginLeft: 5 }}>{e.value}</span>
            {e.available ? (
              ""
            ) : (
              <div className="bg-primary text-white px-2 text-xs rounded">
                coming soon
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
}

Select.defaultProps = {
  bgColor: "bg-base",
  menuPlacement: "auto",
  placeholder: "Select Option",
};

export default SelectWithIcons;
