import axios from "axios";

const PlaygroundService = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_CHAT_AI_ENDPOINT + "/api/v1/playground",
    headers: {
      "Content-Type": "application/json",
      "X-XSS-Protection": "X-XSS-Protection: 1; mode=block",
      "X-Frame-Options": "SAMEORIGIN",
      "Strict-Transport-Security": "max-age=10000000",
      "Content-Security-Policy": "default-src 'self'; child-src 'none';",
      "Referrer-Policy": "no-referrer",
      "X-Content-Type-Options": "nosniff",
      Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
    },
  };

  let instance = axios.create(defaultOptions);

  return instance;
};

export default PlaygroundService();

const fetchChatbotAnswer = async (payload) => {
  const res = await PlaygroundService().post(`/ask`, payload);
  if (res.status === 200) {
    return res.data;
  }
};

const fetchScorePrompt = async (payload) => {
  const res = await PlaygroundService().post(`/ask/score-prompt`, payload);
  if (res.status === 200) {
    return res.data;
  }
};

export { fetchChatbotAnswer, fetchScorePrompt };
