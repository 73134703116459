import React from 'react'
import { Navigate, useNavigate } from "react-router-dom";
import BaseButton from '../Buttons/BaseButton'


function NoRecordFound() {
    const navigate = useNavigate();

    return (
        <div className='text-center flex justify-start items-center gap-x-3'>
            <p>It seems that no chatbots have been created. Create your first one</p>
            <BaseButton value={"Clicking here"} onClick={() => navigate('/builder/chatbots/new')}/>
        </div>
    )
}

export default NoRecordFound