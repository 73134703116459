import React, { useState, useEffect } from "react";
import Checkbox from "../Forms/Checkbox";
import { fetchCompanyTools } from "../../services/api/ManagerService";
import { useRecoilValue } from "recoil";
import { currentUserState } from "../../states/atoms";

function BuilderToolsTable({ onUpdate }) {
  const currentUser = useRecoilValue(currentUserState);
  const [tools, setTools] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);

  useEffect(() => {
    fetchCompanyTools(currentUser.company_id).then((data) => {
      setTools(data);
    });
  }, []);

  const handleSelection = (toolId) => {
    if (selectedTools.includes(toolId)) {
      setSelectedTools(selectedTools.filter((id) => id !== toolId));
    } else {
      setSelectedTools([...selectedTools, toolId]);
    }
  };

  useEffect(() => {
    onUpdate(selectedTools);
  }, [selectedTools]);

  return (
    <div>
      <table className="w-full text-sm text-left text-gray">
        <thead className="text-xs text-white uppercase bg-primary">
          <tr>
            <th scope="col" className="px-2 py-3"></th>
            <th scope="col" className="px-2 py-3">
              Tool Name
            </th>
            <th scope="col" className="px-6 py-3">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          {tools.map((tool, index) => (
            <tr key={index} className="border-b border-t border-gray bg-white">
              <td className="px-2 py-3">
                <Checkbox
                  id={tool.id}
                  checked={false}
                  onClick={() => handleSelection(tool.tool_name)}
                />
              </td>
              <td className="px-6 py-3">{tool.tool_name}</td>
              <td className="px-6 py-3">{tool.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default BuilderToolsTable;
