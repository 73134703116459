import React from "react";

function MultilineText({ text }) {
  const newText = text.split("\n").map((str, index) => (
    <p key={index}>
      {str}
      <br />
    </p>
  ));

  return <div>{newText}</div>;
}

export default MultilineText;
