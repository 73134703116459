import axios from "axios";
import toast from "react-hot-toast";

const AuthService = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_ENDPOINT + "/api/v1/auth",
    headers: {
      "Content-Type": "application/json",
      "X-XSS-Protection": "X-XSS-Protection: 1; mode=block",
      "X-Frame-Options": "SAMEORIGIN",
      "Strict-Transport-Security": "max-age=10000000",
      "Content-Security-Policy": "default-src 'self'; child-src 'none';",
      "Referrer-Policy": "no-referrer",
      "X-Content-Type-Options": "nosniff",
    },
  };

  let instance = axios.create(defaultOptions);

  return instance;
};

export default AuthService();

const forgotPassword = async (email) => {
  console.log('authserv')
  const res = await AuthService().post("/forgot-password", {
    email: email
  })

  if (res.status === 200) {
    return res.data
  } else {
    return null
  }
}

export {forgotPassword}
