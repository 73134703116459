import axios from "axios";

const CompleteWithAIService = () => {
  const defaultOptions = {
    baseURL:
      process.env.REACT_APP_CHAT_AI_ENDPOINT + "/api/v1/complete-with-ai",
    headers: {
      "X-XSS-Protection": "X-XSS-Protection: 1; mode=block",
      "X-Frame-Options": "SAMEORIGIN",
      "Strict-Transport-Security": "max-age=10000000",
      "Content-Security-Policy": "default-src 'self'; child-src 'none';",
      "Referrer-Policy": "no-referrer",
      "X-Content-Type-Options": "nosniff",
      Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
    },
  };

  let instance = axios.create(defaultOptions);

  return instance;
};

export default CompleteWithAIService();

const fetchColumnsDescription = async (file) => {
  const formdata = new FormData();
  formdata.append(`file`, file, file.name);
  const res = await CompleteWithAIService().post(
    `/table-columns-description`,
    formdata
  );
  if (res.status === 200) {
    return res.data;
  }
};

export { fetchColumnsDescription };
