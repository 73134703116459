import React, { useEffect, useState } from "react";
import PageLayout from "../PageLayout";
import { MdDeleteOutline } from "react-icons/md";
import {
  fetchCompanies,
  deleteCompany,
} from "../../services/api/SuperadminService";
import BaseButton from "../../components/Buttons/BaseButton";
import NewCompanyForm from "./NewCompanyForm";

function Superadmin() {
  const [companies, setCompanies] = useState([]);
  const [showNewCompanyForm, setShowNewCompanyForm] = useState(false);

  useEffect(() => {
    fetchCompanies().then((res) => {
      setCompanies(res);
    });
  }, []);

  const handleDeleteCompany = async (id) => {
    const res = await deleteCompany(id);
    const new_companies = companies.filter((company) => company.id !== id);
    setCompanies(new_companies);
  };

  return (
    <PageLayout>
      {!showNewCompanyForm ? (
        <>
          <div className="p-10">
            <h1 className="text-2xl font-bold">Companies inside Chatto</h1>
          </div>
          <div className="p-10">
            <div className="mt-5 mb-5 flex justify-end">
              <BaseButton
                value={"Create New Company"}
                onClick={() => setShowNewCompanyForm(true)}
              />
            </div>
            <div className="overflow-auto">
              <table className="table-fixed w-full">
                <thead>
                  <tr className="text-left">
                    <th className="w-40 p-5 sticky left-0 bg-primary text-white capitalize">
                      Company Name
                    </th>
                    <th className="w-72 p-5 bg-primary text-white">
                      S3 Bucket
                    </th>
                    <th className="w-72 p-5 bg-primary text-white">
                      S3 Bucket Key
                    </th>
                    <th className="w-72 p-5 bg-primary text-white">Region</th>
                    <th className="w-72 p-5 bg-primary text-white">
                      Vectorestore Host
                    </th>
                    <th className="w-72 p-5 bg-primary text-white">
                      Time Created
                    </th>
                    <th className="w-72 p-5 bg-primary text-white">
                      Max Daily Queries
                    </th>
                    <th className="w-72 p-5 bg-primary text-white">
                      Max Chatbots
                    </th>
                    <th className="w-72 p-5 bg-primary text-white">
                      Max Datasources Size MB
                    </th>
                    <th className="w-72 p-5 bg-primary text-white">
                      Connectors
                    </th>
                    <th className="w-20 p-5 bg-primary text-white"></th>
                  </tr>
                </thead>
                <tbody>
                  {companies &&
                    companies.map((item, index) => {
                      return (
                        <tr key={index} className="text-left border-b">
                          <td className="w-10 py-5 px-2 sticky left-0 bg-secondary-light">
                            {item.company_name}
                          </td>
                          <td className="w-40 p-5">{item.s3_bucket}</td>
                          <td className="p-5">{item.s3_bucket_key}</td>
                          <td className="p-5">{item.region}</td>
                          <td className="p-5">{item.vectorestore_host}</td>
                          <td className="p-5">{item.time_created}</td>
                          <td className="p-5">
                            {item.constraints.max_daily_query}
                          </td>
                          <td className="p-5">
                            {item.constraints.max_chatbots}
                          </td>
                          <td className="p-5">
                            {item.constraints.max_datasource_size_mb}
                          </td>
                          <td>
                            {item.connectors &&
                              item.connectors.map((connector, index) => {
                                return (
                                  <div key={index}>
                                    {connector.connector_name}
                                  </div>
                                );
                              })}
                          </td>
                          <td>
                            <button className="p-2 bg-red-500 text-white rounded">
                              <MdDeleteOutline
                                className="text-2xl"
                                onClick={() => handleDeleteCompany(item.id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <NewCompanyForm />
      )}
    </PageLayout>
  );
}

export default Superadmin;
