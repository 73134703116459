import { useRecoilState } from "recoil";
import {
  promptScoreState,
  chatbotCountState,
  datastoresState,
  chatbotSizeState,
  queryCountState,
  userState,
  notValidFilesState,
  currentUserState,
  currentCompanyState,
  companyConstraintsState,
  currentChatbotConfigState,
  favoriteChatbotState,
  modalUploadFileState,
} from "./atoms";

export const usePromptScoreState = () => {
  const [promptScore, setPromptScore] = useRecoilState(promptScoreState);
  return [promptScore, setPromptScore];
};

export const useChatbotCountState = () => {
  const [chatbotCount, setChatbotCount] = useRecoilState(chatbotCountState);
  return [chatbotCount, setChatbotCount];
};

export const useDatastoresState = () => {
  const [datastores, setDatastores] = useRecoilState(datastoresState);
  return [datastores, setDatastores];
};

export const useChatbotSizeState = () => {
  const [size, setSize] = useRecoilState(chatbotSizeState);
  return [size, setSize];
};

export const useQueryCountState = () => {
  const [queryCount, setQueryCount] = useRecoilState(queryCountState);
  return [queryCount, setQueryCount];
};

export const useNotValidFilesState = () => {
  const [notValidFiles, setNotValidFiles] = useRecoilState(notValidFilesState);
  return [notValidFiles, setNotValidFiles];
};

export const useCurrentUserState = () => {
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  return [currentUser, setCurrentUser];
};

export const useCurrentCompanyState = () => {
  const [currentCompany, setCurrentCompany] =
    useRecoilState(currentCompanyState);
  return [currentCompany, setCurrentCompany];
};

export const useCompanyConstraintsState = () => {
  const [companyConstraints, setCompanyConstraints] = useRecoilState(
    companyConstraintsState
  );
  return [companyConstraints, setCompanyConstraints];
};

export const useCurrentChatbotConfigState = () => {
  const [currentChatbotConfig, setCurrentChatbotConfig] = useRecoilState(
    currentChatbotConfigState
  );
  return [currentChatbotConfig, setCurrentChatbotConfig];
};

export const useFavoriteChatbotState = () => {
  const [favoriteChatbot, setFavoriteChatbot] =
    useRecoilState(favoriteChatbotState);
  return [favoriteChatbot, setFavoriteChatbot];
};

export const useModalUploadFileState = () => {
  const [modalUploadFile, setModalUploadFile] =
    useRecoilState(modalUploadFileState);
  return [modalUploadFile, setModalUploadFile];
};
