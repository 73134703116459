import React, { useState } from "react";
import { BiCopy } from "react-icons/bi";

function UserMessage({ message, prompts, onCopy }) {
  const [text, setText] = useState(message);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    onCopy(text);
  };

  return (
    <div className="flex p-5 gap-x-5 border-b items-start">
      <div className="bg-gray-500 text-white font-bold px-4 py-1 rounded-xl">
        USER
      </div>
      <div className="w-full">
        <div
          className={`text-xs flex flex-col ${prompts.length > 0 ? "" : ""}`}
        >
          {/* <span className={`${prompts.length > 0 ? "font-bold" : "hidden"}`}>
            Prompt
          </span> */}
          {/* {prompts.map((prompt,index) => {
              return <span className='italic' key={index}>{prompt.text}</span>
            })} */}
        </div>

        <div className="flex justify-between items-center">
          <p className="mt-2">{message}</p>
          <BiCopy
            className="text-xl hover:text-primary cursor-pointer"
            onClick={handleCopy}
          />
        </div>
      </div>
    </div>
  );
}

export default UserMessage;
