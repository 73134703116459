import React, { useEffect, useState } from "react";
import Logo from "./Logo-extended.png";
import Input from "../../components/Forms/Input";
import BaseButton from "../../components/Buttons/BaseButton";
import { loginAsSuperadmin } from "../../services/api/SuperadminService";

function SuperadminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    const res = await loginAsSuperadmin(email, password);
    if (res.status === 200) {
      localStorage.setItem(
        "localAuthorizationSuperAdmin",
        res.data.access_token
      );
      window.location.href = "/superadmin";
    } else {
      alert("Login failed");
    }
  };

  return (
    <div className="flex h-screen justify-center items-center w-full">
      <div className="bg-white w-1/2 p-5 rounded shadow">
        <div className="flex items-center justify-center flex-col gap-5">
          <img src={Logo} className="h-14" />
          <h1 className="text-xl font-bold">LOGIN AS SUPERADMIN</h1>
        </div>
        <div className="py-5 flex flex-col gap-5">
          <Input
            type={"text"}
            label={"Email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type={"password"}
            label={"Password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="w-full flex justify-center">
            <BaseButton value={"Login"} onClick={handleLogin} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperadminLogin;
