import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Menu } from "lucide-react";

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserWithStats from "./UserWithStats";

import { sidebarMenu } from "../../services/SidebarService";
import DefaultProgressBar from "../ProgressBar/DefaultProgressBar";
import {
  fetchChatbots,
  fetchChatbotsTotalSizeInMb,
} from "../../services/api/ManagerService";
import { fetchQueryUsage } from "../../services/api/UsersService";
import {
  useChatbotCountState,
  useChatbotSizeState,
  useQueryCountState,
} from "../../states/useStates";

import { useRecoilValue } from "recoil";
import { currentUserState, companyConstraintsState } from "../../states/atoms";
import User from "./User";
import { cn } from "@/lib/utils";

const DrawerSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [filteredSidebarMenu, setFilteredSidebarMenu] = useState(sidebarMenu);

  const [totalSize, setTotalSize] = useChatbotSizeState();
  const [totalSizePercentage, setTotatSizePercentage] = useState();

  const [chatbotCount, setChatbotCount] = useChatbotCountState();
  const [queryCount, setQueryCount] = useQueryCountState();
  const user = useRecoilValue(currentUserState);
  const companyConstraints = useRecoilValue(companyConstraintsState);

  const calculatePercentageTotSize = () => {
    return (totalSize * 100) / companyConstraints.max_datasource_size_mb;
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "sandbox") {
      setFilteredSidebarMenu(
        sidebarMenu.filter((item) => item.title !== "Chat")
      );
    }

    if (!user.is_admin) {
      setFilteredSidebarMenu(
        sidebarMenu.filter((item) => item.title !== "Admin Area")
      );
    }

    fetchChatbots()
      .then((res) => setChatbotCount(res.length))
      .catch((err) => console.log(err));

    fetchChatbotsTotalSizeInMb()
      .then((res) => setTotalSize(res.total_size_mb))
      .catch((err) => console.log(err));
    setTotatSizePercentage(calculatePercentageTotSize());

    fetchQueryUsage()
      .then((res) => setQueryCount(res.query_usage))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="ghost">
          <Menu />
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="w-80">
        <SheetHeader>
          <SheetTitle className="text-2xl">Chatto</SheetTitle>
        </SheetHeader>
        <div className="h-screen relative mt-10">
          <div>
            <div className="flex justify-center flex-col items-center gap-5">
              <span className="font-bold text-primary uppercase">
                {process.env.REACT_APP_ENVIRONMENT === "sandbox" &&
                  process.env.REACT_APP_ENVIRONMENT}
              </span>
            </div>
            <div className="text-primary px-3">
              <div className="mb-5">
                <Button
                  className="font-semibold rounded-lg w-full hover:bg-secondary"
                  onClick={() => navigate("/builder/chatbots/new")}
                >
                  + Create New Chatbot
                </Button>
              </div>
              <ul>
                {filteredSidebarMenu.map((data, index) => {
                  return (
                    <li
                      className={cn(
                        "py-2 mb-3 cursor-pointer border-primary hover:border-l-4 hover:px-2",
                        location.pathname === data.to && "border-l-4 px-2"
                      )}
                      key={index}
                    >
                      <Link
                        className="flex items-center gap-x-3 text-xs"
                        to={data.to}
                      >
                        {data.icon}
                        {data.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <div className="absolute bottom-28 w-5/6">
                {/* metrics */}
                <div className="border border-primary p-4 rounded border-dotted mb-10">
                  {/* usage report */}

                  <div className="flex flex-col gap-4">
                    <div>
                      <h5 className="text-xs font-bold mb-2">USAGE SIZE</h5>
                      <div className="w-full flex justify-between text-xs mb-1">
                        <span>{Math.round(totalSize)}MB</span>
                        <span>
                          {Math.round(
                            companyConstraints.max_datasource_size_mb
                          )}
                          MB
                        </span>
                      </div>
                      <div className="w-full">
                        <DefaultProgressBar
                          styleClass={`${
                            totalSizePercentage === 100
                              ? "bg-red-500"
                              : "bg-primary"
                          }`}
                          percentage={totalSizePercentage}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <h5 className="text-xs font-bold">CHATBOTS:</h5>
                      <span>
                        {chatbotCount} / {companyConstraints.max_chatbots}
                      </span>
                    </div>

                    <div className="flex items-center justify-between">
                      <span className="text-xs font-bold">DAILY QUERIES:</span>
                      <span className="ml-4">
                        {companyConstraints.max_daily_query - queryCount}
                      </span>
                    </div>
                  </div>
                </div>
                {/* end - metrics */}

                {user ? (
                  <User
                    fullname={user.fullname}
                    company={localStorage.getItem("company_name")}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* end - sidebar open */}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default DrawerSidebar;
