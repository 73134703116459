import React from 'react'

function Tag({name, onDelete}) {
    return (
        <div className='bg-primary text-white rounded py-1 px-2 w-fit flex justify-between gap-x-5 items-center'>
            <p>{name}</p>
            <span className='text-xs cursor-pointer' onClick={onDelete}>x</span>
        </div>
    )
}

export default Tag