import { useEffect } from "react";
import { Input } from "@/components/ui/input";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "@/components//ui/form";
import { Button } from "@/components/ui/button";
import { toast } from "react-hot-toast";
import {Send} from 'lucide-react'
const formSchema = z.object({
  message: z.string().min(1, {
    message: "Your message cannot be empty!",
  }),
});

const MobileChatInput = ({ chatbot, tool, handleSendMessage }) => {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: "",
    },
  });

  const isLoading = form.formState.isSubmitting;

  const onSubmit = async (values) => {
    try {
      await handleSendMessage(values.message, chatbot.chatbot_name, tool ? tool : "");
    } catch (error) {
      console.log(error);
    } finally {
      form.setValue("message", "");
    }
  };

  useEffect(() => {
   console.log(form.formState.errors)
   if (form.formState.errors.message?.message) {

     toast.error(form.formState.errors.message?.message)
   }
  }, [form.formState.errors])
  
  return (
    <div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-full flex items-center gap-2 "
        >
          <FormField
            control={form.control}
            name="message"
            className="w-full"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <div>
                    <Input
                      placeholder="Send a message to the chatbot..."
                      className="w-full focus-visible:ring-primary focus:ring-primary"
                      disabled={isLoading}
                      {...field}
                    />
                  </div>
                </FormControl>
              </FormItem>
            )}
          />
          <Button
            className="bg-primary ml-auto "
            type="submit"
            disabled={isLoading}
          >
            <Send />
          </Button>
        </form>
      </Form>
    </div>
  );
};

export default MobileChatInput;
