import React from "react";
import NotAvailable from '../Badges/NotAvailable'

const Connector = ({ name, icon, provider, selected, available, onClick }) => {
  const handleClick = () => {
    onClick(name);
  };
  return (
    <div>
      <div className="relative">
        <div
          onClick={handleClick}
          className={`shadow h-24 w-24 lg:h-32 lg:w-32 l rounded-xl
          flex flex-col items-center justify-center gap-y-2 
          ${selected ? "bg-primary text-white" : "bg-white"} ${
            available
              ? "hover:border hover:border-primary cursor-pointer"
              : "cursor-not-allowed bg-gray opacity-50"
          }`}
        >
          {icon}
          <div className="flex flex-col text-center">
            <span className="text-xs font-bold capitalize">{provider}</span>
            <span className="text-xs">{name}</span>
          </div>
          <div className={`${available ? "hidden" : "absolute bottom-1"}`}>
            <NotAvailable message={"cooming soon"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connector;
