import axios from "axios";

const PipelineService = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_ENDPOINT + "/api/v1/connectors",
    headers: {
      "X-XSS-Protection": "X-XSS-Protection: 1; mode=block",
      "X-Frame-Options": "SAMEORIGIN",
      "Strict-Transport-Security": "max-age=10000000",
      "Content-Security-Policy": "default-src 'self'; child-src 'none';",
      "Referrer-Policy": "no-referrer",
      "X-Content-Type-Options": "nosniff",
      Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
    },
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default PipelineService();

const executePipeline = async (connector_type, payload) => {
  const res = await PipelineService().post(`/${connector_type}/load`, payload);
  if (res.status === 200) {
    return res.data;
  }
};

const fetchColumnsNames = async (file) => {
  const formdata = new FormData();
  formdata.append(`file`, file, file.name);
  const res = await PipelineService().post(
    `/structured-text/file/columns`,
    formdata
  );
  if (res.status === 200) {
    return res.data;
  }
};

export { executePipeline, fetchColumnsNames };
