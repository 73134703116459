import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { fetchUserChatbotsTools } from "../../services/api/UsersService";
import { AiOutlineRobot } from "react-icons/ai";
import { CiSearch } from "react-icons/ci";
import { PiSparkle } from "react-icons/pi";

function DialogSelectTool({ onToolSelect, currentTool, chatbot }) {
  const [toolList, setToolList] = useState([]);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fn();
  }, [chatbot]);

  const fn = async () => {
    if (chatbot) {
      const tools = await fetchUserChatbotsTools(chatbot).catch((e) => {
        console.log(e);
      });
      setToolList(tools);
    }
  };

  const handleSelection = (tool) => {
    onToolSelect(tool.tool_name);
    setOpen(false);
    setSearch("");
  };

  const filteredTools = toolList.filter((tool) =>
    tool.tool_name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className={`flex items-center text-sm w-fit gap-2 ${
          currentTool ? "opacity-100 text-primary" : "opacity-30"
        }  hover:opacity-100 cursor-pointer`}
      >
        <PiSparkle />
        {currentTool ? <span>{currentTool}</span> : <span>Select tool</span>}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-primary">
            Select a specific tool to complete a task
          </DialogTitle>
          <DialogDescription>
            <div className="flex gap-2 items-center w-full justify-center border-b mt-2">
              <CiSearch />
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search..."
                className="p-2 w-full outline-none border-none bg-transparent"
              />
            </div>
            <div className="mt-3 opacity-50 text-xs">Available tools</div>

            <div className="flex flex-col gap-2 mt-2">
              <div
                className="flex gap-1 cursor-pointer flex-col hover:bg-primary hover:text-white p-2 rounded"
                onClick={() => handleSelection("")}
              >
                <h3 className="font-bold">No tool</h3>
              </div>
              {filteredTools.map((tool, i) => (
                <div
                  key={i}
                  className={`flex gap-1 cursor-pointer flex-col hover:bg-primary hover:text-white p-2 rounded ${
                    currentTool === tool.tool_name
                      ? "bg-primary text-white"
                      : ""
                  }`}
                  onClick={() => handleSelection(tool)}
                >
                  <h3 className="font-bold">{tool.tool_name}</h3>
                  <p className="text-xs">{tool.tool_description}</p>
                </div>
              ))}
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export default DialogSelectTool;
