import React from 'react'
import { useNavigate } from "react-router-dom";

const PageUnauthorized = () => {
  const navigate = useNavigate();
  return (
      <div className="h-screen w-screen bg-[#5CB6C4] flex items-center gap-8 justify-center flex-col">
        <div className='text-4xl h-1/2'>Unauthorized.</div>
        <h1
          className="text-2xl font-bold text-white underline cursor-pointer"
          onClick={() => navigate("/")}
        >
          Back to Home
        </h1>
      </div>
  )
}

export default PageUnauthorized