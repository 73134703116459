import React from 'react'

function Checkbox({onClick, checked}) {
  return (
    <>
        <input 
          id="default-checkbox" 
          type="checkbox" 
          className="w-4 h-4 bg-base border-gray/40 rounded focus:ring-prmary focus:ring-2" 
          onClick={onClick}
          defaultChecked={checked}
          />
    </>
  )
}

export default Checkbox