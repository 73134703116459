import React from 'react'
import { FaInfoCircle } from 'react-icons/fa';

function InfoBanner({children}) {
    return (
        <div className='bg-gray/20 p-2 rounded text-xs mb-3 flex gap-x-3 items-start'>
            <FaInfoCircle />
            <div>
                {children}
            </div>
        </div>
    )
}

export default InfoBanner