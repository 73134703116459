import React, { useState, useEffect } from "react";
import Checkbox from "../Forms/Checkbox";
import ManagerService from "../../services/api/ManagerService";
import UsersService from "../../services/api/UsersService";
import { useRecoilValue } from "recoil";
import { currentUserState } from "../../states/atoms";
// import AuthService from "../../services/api/AuthService";

function UsersPermissionsTable({ onUpdate }) {
  const currentUser = useRecoilValue(currentUserState);
  const [users, setUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await UsersService.get("", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
        },
      });
      if (res.status === 200) {
        res.data.map((user) => {
          user.access = false;
        });
        await setUsers(res.data);
      }
    };

    fetchUsers().catch((e) => console.log(e));
  }, []);

  const handleUserPermission = (emailTarget, role) => {
    const updatedUser = users.map((user) => {
      if (user.email === emailTarget) {
        if (role === "access") {
          return { ...user, access: !user.access };
        }
      } else {
        return user;
      }
    });
    setUsers(updatedUser);
  };

  useEffect(() => {
    let newPermissions = [];
    for (let index = 0; index < users.length; index++) {
      if (users[index].email === currentUser.email) {
        newPermissions.push({
          sub: users[index].sub,
          email: users[index].email,
          access: true,
        });
      }
      if (users[index].access !== undefined) {
        newPermissions.push({
          sub: users[index].sub,
          email: users[index].email,
          access:
            users[index].access !== undefined ? users[index].access : false,
        });
      }
    }
    setPermissions(newPermissions);
    onUpdate(newPermissions);
  }, [users]);

  useEffect(() => {
    const updatedUser = users.map((user) => {
      return { ...user, access: false };
    });
    setUsers(updatedUser);
  }, []);

  // console.log(permissions)
  // console.log(users);

  return (
    <div>
      <table className="w-full text-sm text-left text-gray">
        <thead className="text-xs text-white uppercase bg-primary">
          <tr>
            <th scope="col" className="px-2 py-3">
              Chat Access
            </th>
            <th scope="col" className="px-6 py-3">
              Fullname
            </th>
            <th scope="col" className="px-6 py-3">
              Email
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((u, i) => {
            return (
              <tr
                className="bg-white border-b-2 border-base border-opacity-50"
                key={i}
              >
                <td className="px-6">
                  <Checkbox
                    onClick={() => handleUserPermission(u.email, "access")}
                    checked={u.email == currentUser.email ? true : false}
                  />
                </td>
                <td scope="row" className="px-6 py-4 whitespace-nowrap">
                  {u.fullname}
                </td>
                <td scope="row" className="px-6 py-4 whitespace-nowrap">
                  {u.email}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default UsersPermissionsTable;
