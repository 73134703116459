import React from "react";
import PromptQuality from "../Badges/PromptQuality";

function TextArea({ text, label, placeholder, onUpdate, styleClass }) {
  return (
    <div className={`flex flex-col gap-y-2  ${styleClass}`}>
      <div className="flex flex-col lg:flex-row justify-between gap-2 items-center">
        <div className="text-xs flex flex-col">
          <label className="font-bold uppercase text-xs">{label}</label>
          <span>Insert your system prompt instructions</span>
        </div>
        <PromptQuality />
      </div>

      <div className="border border-grayCustom/20 h-full rounded">
        <textarea
          placeholder={placeholder}
          className={`bg-base rounded w-full h-full resize-none p-3 outline-none`}
          onChange={(e) => onUpdate(e.target.value)}
          value={text}
        />
      </div>
    </div>
  );
}

export default TextArea;
