import { Routes, Route } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Login from "./pages/Login/Login";
import BuilderViewKnowledgeBase from "./pages/KnowledgeBase/ViewKnowledgeBase";
import BuilderCreateNewChatbot from "./pages/Builder/CreateNewChatbot";
import ViewSingleKnowledgeBase from "./pages/KnowledgeBase/ViewSingleKnowledgeBase";
import Playground from "./pages/Playground/Playground";
import Chat from "./pages/Chat/Chat";
import { AccountContext } from "./services/AccountService";
import ManagerService from "./services/api/ManagerService";
import Admin from "./pages/Admin/Admin";
import Superadmin from "./pages/Superadmin/Superadmin";
import SuperadminLogin from "./pages/Superadmin/SuperadminLogin";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import { Toaster } from "react-hot-toast";
import { isMobile } from "react-device-detect";
import MobileChat from "./pages/MobileChat/MobileChat";
import ChatV2 from "./pages/ChatV2/ChatV2";

function App() {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const { sessionToken, userPermission, whoAmI, whichCompany, refreshToken } =
    useContext(AccountContext);
  const [user, setUser] = useState(null);
  console.log("app");
  useEffect(() => {
    // config all requests with auth token
    ManagerService.interceptors.request.clear();

    let authToken;
    if (sessionToken) {
      authToken = sessionToken;
    } else {
      authToken = localStorage.getItem("localAuthorization");
    }

    if (ManagerService.interceptors.request.handlers.length === 0) {
      ManagerService.interceptors.request.use((config) => {
        config.headers.Authorization = "Bearer " + authToken;
        return config;
      });
    }

    // save user role
    if (authToken) {
      whoAmI()
        .then((res) => {
          setIsTokenValid(true);
          setUser(res.data);
        })
        .catch((err) => {
          if (err.response && err.response.status === 403) {
            refreshToken(authToken);
          } else {
            // console.log(formatErrorForAlert(err));
            console.log(err);
            setIsTokenValid(false);
          }
        });
    } else {
      setIsTokenValid(false);
    }
  }, [sessionToken]);

  return isTokenValid && !window.location.pathname.includes("/superadmin") ? (
    <main className="bg-base font-roboto text-gray">
      <Toaster />
      {/* {isMobile ? (
        <Routes>
          <Route path="/" element={<MobileChat />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      ) : ( */}
      <Routes>
        <Route path="/" element={<BuilderViewKnowledgeBase />} />
        <Route path="/admin" element={<Admin user={user} />} />
        <Route
          path="/knowledgebase/:datastore"
          element={<ViewSingleKnowledgeBase />}
        />
        <Route
          path="/builder/chatbots/new"
          element={<BuilderCreateNewChatbot />}
        />
        <Route path="/playground" element={<Playground />} />
        {/* <Route path="/chat" element={<Chat />} /> */}
        <Route path="/chat" element={<ChatV2 />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {/* )} */}
    </main>
  ) : window.location.pathname === "/superadmin/login" ? (
    <SuperadminLogin />
  ) : window.location.pathname === "/superadmin" ? (
    <Superadmin />
  ) : (
    <>
      <Toaster />
      <Login isMobile={isMobile} />
    </>
  );
}

export default App;
